import React from 'react'
import Loader from 'components/loader/Loader'
import styles from './ListArea.module.scss'

function ListArea({ title, loading, ...props }) {
  return (
    <div>
      {title && <h2 className={styles.header}>{title}</h2>}
      <div className={`${styles.container} shadow`}>
        {loading && <Loader />}
        <div className={styles.items}>{props.children}</div>
      </div>
    </div>
  )
}

export default ListArea
