import { API, Storage } from 'aws-amplify'
import Alert from 'components/alert/Alert'
import BlockArea from 'components/block-area/BlockArea'
import BlockItem from 'components/block-area/BlockItem'
import Button from 'components/button/Button'
import FormBody from 'components/form/form-body/FormBody'
import FormRow from 'components/form/form-row/FormRow'
import Label from 'components/form/label/Label'
import ImageCropper from 'components/ImageCropper'
import TextInput from 'components/form/text-input/TextInput'
import MessageBox from 'components/message-box/MessageBox'
import PostContent, { validatePostContent } from 'components/post-content/PostContent'
import S3Image from 'components/s3-image/S3Image'
import config from 'config'
import { validateImageSize } from 'libs/image-size-checker'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './Form.module.scss'

const dataURItoBlob = async dataURI => {
  const entity = await fetch(dataURI)
  return await entity.blob()
}

function Form(props) {
  const { id } = useParams()
  const [initialLoad, setInitialLoad] = useState(true)
  const [loading, setLoading] = useState(false)
  const [alertSettings, setAlertSettings] = useState({
    show: false,
    message: '',
    type: 'info',
  })
  const [values, setValues] = useState({
    content: { value: '', error: '' },
    shortContent: { value: '', error: '' },
    imageUrl: { value: '', error: '' },
  })
  const [oldImg, setOldImg] = useState('')
  const [oldImgUrl, setOldImgUrl] = useState('')
  const [imageBase64, setImageBase64] = useState()
  const [editedImageBase64, setEditedImageBase64] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const data = await API.get('schedule', `/${id}`)
      const dataValues = {
        content: { value: data.content },
        shortContent: { value: data.shortContent ?? '' },
        imageUrl: { value: '' },
      }
      setOldImg(data.imageUrl)
      setOldImgUrl(data.imageUrl)
      setValues(dataValues)
      setInitialLoad(false)
    }

    fetchData()
  }, [id, setValues])

  const validateForm = async () => {
    return await validatePostContent(values, setValues)
  }

  const validateImage = async (file = '') => {
    const name = 'imageUrl'
    if (!file) {
      file = values.imageUrl.value
    }
    const maxSize = config.POST_IMG_MAX_SIZE
    const types = config.POST_IMG_TYPES

    if (!file) {
      setValues({ ...values, [name]: { error: `An image is required` } })
      return false
    }

    if (!types.includes(file.type)) {
      setValues({ ...values, [name]: { error: `Invalid image type` } })
      return false
    }

    if (file.size > maxSize) {
      setValues({
        ...values,
        [name]: { error: `Image is too big (${maxSize / 1000000}MB limit)` },
      })
      return false
    }

    const validSize = await validateImageSize(file)
    if (!validSize) {
      setValues({
        ...values,
        [name]: {
          error: `Image must be ${config.POST_IMG_WIDTH} x ${config.POST_IMG_HEIGHT}`,
        },
      })
      return false
    }

    return true
  }

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleFileChange = async event => {
    const { name } = event.target
    const file = event.target.files[0]

    const valid = await validateImage(file)
    if (!valid) {
      setImageBase64('')
      return false
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      setValues({ ...values, [name]: { value: file } })
      reader.onloadend = () => {
        setImageBase64(reader.result)
        setOldImg('')
        setOldImgUrl('')
      }
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()

    const validForm = await validateForm()
    const validImage = oldImg || (await validateImage())

    if (validForm && validImage) {
      setLoading(true)

      try {
        const image = await uploadImage()
        const body = {
          content: values.content.value,
          shortContent: values.shortContent.value,
        }
        body.imageUrl = image
        await API.put('schedule', `/${id}`, {
          body: body,
        })

        props.history.push({ pathname: '/schedule' })
      } catch (error) {
        setAlertSettings({
          show: true,
          type: 'error',
          message: error.response.data.message || 'There was an error updating the post',
        })
      }

      setLoading(false)
    }
  }

  const uploadImage = async () => {
    const file = values.imageUrl.value
    if (file) {
      const editedFile = await dataURItoBlob(editedImageBase64)
      const safeName = file.name.replace(/ /g, '_').replace(/\.[^/.]+$/, '') // spaces to underscore and remove file extension
      const name = `posts/CIma${Date.now()}-${safeName}.jpg`
      const image = await Storage.put(name, editedFile, {
        contentType: editedFile.type,
      })
      return image.key
    } else {
      return oldImg
    }
  }

  return (
    <div>
      <MessageBox
        content={`You can customise the content of a scheduled post as often as you like.`}
      />
      {alertSettings.show && (
        <Alert type={alertSettings.type}>{alertSettings.message}</Alert>
      )}

      <BlockArea title='Customise Post' cols={1}>
        {!initialLoad && (
          <BlockItem label='Post Details' type='bg-purple'>
            <form onSubmit={handleSubmit}>
              <FormBody>
                <PostContent values={values} handleChange={handleChange} />
                {oldImg && (
                  <FormRow>
                    <S3Image
                      path={oldImgUrl}
                      height='220'
                      width='360'
                      className={styles.image}
                    />
                  </FormRow>
                )}
                <FormRow>
                  <Label text='Change Image' />
                  <TextInput
                    name='imageUrl'
                    type='file'
                    error={values.imageUrl.error}
                    onChange={handleFileChange.bind(this)}
                  />
                  <p>
                    Please upload your image at a size of {config.POST_IMG_WIDTH} x{' '}
                    {config.POST_IMG_HEIGHT}
                  </p>
                </FormRow>

                <ImageCropper imageBase64={imageBase64} onChange={setEditedImageBase64} />
              </FormBody>

              <div>
                <Button label='Update' loading={loading} />
              </div>
            </form>
          </BlockItem>
        )}
      </BlockArea>
    </div>
  )
}

export default Form
