import React from 'react'
import styles from './ScheduleTitle.module.scss'

function ScheduleTitle(props) {
  return (
    <div className={styles.title}>
      {props.children}
    </div>
  )
}

export default ScheduleTitle
