import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './BlockItem.module.scss'

function BlockItem({ label, type, style, to, ...props }) {
  if (to) {
    return (
      <NavLink className={`${styles.item}`} to={to} exact>
        <div className={`${styles.header} ${type}`} style={style}>{label}</div>
        <div className={styles.content}>{props.children}</div>
      </NavLink>
    )
  } else {
    return (
      <div className={`${styles.item}`} to={to}>
        <div className={`${styles.header} ${type}`}>{label}</div>
        <div className={styles.content}>{props.children}</div>
      </div>
    )
  }
}

export default BlockItem
