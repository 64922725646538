import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import Button from 'components/button/Button'
import Modal from 'react-modal'
import styles from './IconButton.module.scss'
import './IconButton.css'

function IconButton({onClick, onClickId, text = 'Are you sure want to action this?'}) {
  const [open, setOpen] = useState(false)

  const customStyles = {
    content: {
      top: '30%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  const toggleModal = () => {
    setOpen(!open)
  }

  const handleClick = () => {
    onClick(onClickId)
  }

  Modal.setAppElement('#root')

  return (
    <div className={styles.container}>
      <FontAwesomeIcon onClick={toggleModal} className={styles.action} icon={faLink} />

      <Modal
        isOpen={open}
        onRequestClose={toggleModal}
        style={customStyles}
        contentLabel='Example Modal'>
        <div className={styles.text}>{text}</div>
        <div className={styles.actions}>
        <Button label='Cancel' onClick={toggleModal} />
          <Button type='bg-green' label='Confirm' onClick={handleClick} />
        </div>
      </Modal>
    </div>
  )
}

export default IconButton
