import React from 'react'
import { LinkedInPopUp } from 'react-linkedin-login-oauth2'
import { Redirect, Route, Switch } from 'react-router-dom'
import AuthenticatedRoute from './components/routes/AuthenticatedRoute'
import UnauthenticatedRoute from './components/routes/UnauthenticatedRoute'
import Admins from './containers/admins/Admins'
import AdminsForm from './containers/admins/Form'
import Categories from './containers/categories/Categories'
import CategoriesForm from './containers/categories/Form'
import Distributors from './containers/distributors/Distributors'
import DistributorsForm from './containers/distributors/Form'
import FacebookAnalyticsOverview from './containers/facebook/analytics/Overview'
import LinkedinAnalyticsOverview from './containers/linkedin/analytics/Overview'
import Login from './containers/login/Login'
import Logout from './containers/logout/Logout'
import ManageLinkedIn from './containers/manage-linkedin/ManageLinkedin'
import NotFound from './containers/not-found/NotFound'
import PostsCustomForm from './containers/posts/CustomForm'
import PostsForm from './containers/posts/Form'
import Posts from './containers/posts/Posts'
import ResetPassword from './containers/reset-password/ResetPassword'
import Schedule from './containers/schedule/Schedule'
import ScheduleForm from './containers/schedule/update/Form'
import SocialMediaConnect from './containers/social-media-connect/SocialMediaConnect'
import TipsForm from './containers/tips/Form'
import Tips from './containers/tips/Tips'
import TwitterAuthorise from './containers/twitter/Authorise'
import UsersForm from './containers/users/form/Form'
import Users from './containers/users/Users'
import Welcome from './containers/welcome/Welcome'

export default ({ childProps }) => (
  <Switch>
    <AuthenticatedRoute path='/' exact>
      <Redirect to='/social-posts' />
    </AuthenticatedRoute>
    <AuthenticatedRoute path='/social-posts' exact component={Posts} />
    <AuthenticatedRoute
      path='/social-posts/category/:categoryId/:categoryName'
      exact
      component={Posts}
    />
    <AuthenticatedRoute
      roles={['admin']}
      path='/social-posts/create'
      exact
      component={PostsForm}
    />
    <AuthenticatedRoute
      roles={['admin']}
      path='/social-posts/update/:id'
      exact
      component={PostsForm}
    />
    <AuthenticatedRoute path='/custom-post/create' exact component={PostsCustomForm} />

    <AuthenticatedRoute path='/categories' exact component={Categories} />
    <AuthenticatedRoute
      roles={['admin']}
      path='/categories/create'
      exact
      component={CategoriesForm}
    />
    <AuthenticatedRoute
      roles={['admin']}
      path='/categories/update/:id'
      exact
      component={CategoriesForm}
    />

    <AuthenticatedRoute
      roles={['user', 'distributor']}
      path='/schedule'
      exact
      component={Schedule}
    />
    <AuthenticatedRoute
      roles={['user', 'distributor']}
      path='/schedule/update/:id'
      exact
      component={ScheduleForm}
    />
    <AuthenticatedRoute path='/social-tips' exact component={Tips} />
    <AuthenticatedRoute
      roles={['admin']}
      path='/social-tips/create'
      exact
      component={TipsForm}
    />
    <AuthenticatedRoute
      roles={['admin']}
      path='/social-tips/update/:id'
      exact
      component={TipsForm}
    />

    <AuthenticatedRoute
      roles={['user', 'distributor']}
      path='/social-media-connect'
      exact
      component={SocialMediaConnect}
    />
    <AuthenticatedRoute
      roles={['user', 'distributor']}
      path='/social-media-connect/linkedin'
      exact
      component={ManageLinkedIn}
    />
    <AuthenticatedRoute
      roles={['user', 'distributor']}
      path='/linkedin'
      exact
      component={LinkedInPopUp}
    />
    <AuthenticatedRoute roles={['distributor']} path='/users' exact component={Users} />
    <AuthenticatedRoute
      roles={['distributor']}
      path='/users/create'
      exact
      component={UsersForm}
    />
    <AuthenticatedRoute
      roles={['distributor']}
      path='/users/update/:id'
      exact
      component={UsersForm}
    />
    <AuthenticatedRoute
      roles={['user', 'distributor']}
      path='/linkedin/analytics'
      exact
      component={LinkedinAnalyticsOverview}
    />
    <AuthenticatedRoute
      path='/facebook/analytics'
      exact
      component={FacebookAnalyticsOverview}
    />
    <AuthenticatedRoute
      roles={['user', 'distributor']}
      path='/twitter/authorise'
      exact
      component={TwitterAuthorise}
    />

    <AuthenticatedRoute roles={['admin']} path='/admins' exact component={Admins} />
    <AuthenticatedRoute
      roles={['admin']}
      path='/admins/create'
      exact
      component={AdminsForm}
    />
    <AuthenticatedRoute
      roles={['admin']}
      path='/admins/update/:id'
      exact
      component={AdminsForm}
    />

    <AuthenticatedRoute
      roles={['admin']}
      path='/distributors'
      exact
      component={Distributors}
    />
    <AuthenticatedRoute
      roles={['admin']}
      path='/distributors/create'
      exact
      component={DistributorsForm}
    />
    <AuthenticatedRoute
      roles={['admin']}
      path='/distributors/update/:id'
      exact
      component={DistributorsForm}
    />

    <AuthenticatedRoute path='/logout' exact component={Logout} />
    <UnauthenticatedRoute path='/login' exact component={Login} />
    <UnauthenticatedRoute path='/reset-password' exact component={ResetPassword} />
    <UnauthenticatedRoute path='/welcome/:identifier' exact component={Welcome} />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
)
