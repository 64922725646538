import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from 'aws-amplify'
import Button from 'components/button/Button'
import Loader from 'components/loader/Loader'
import TokenExpired from 'components/token-expired/TokenExpired'
import React, { useEffect, useState } from 'react'
import styles from './Login.module.scss'

function Login(props) {
  const [connected, setConnected] = useState(false)
  const [expired, setExpired] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const res = await API.get('tokens', '/twitter')
        setConnected(true)
        setExpired(res.expired)
        setConnected(true)
        setLoading(false)
      } catch (e) {
        console.log(e)
        setLoading(false)
      }
    }
    fetchToken()
  }, [])

  const getRequestToken = async () => {
    try {
      const data = await API.post('tokens', '/twitter/requestToken')
      let authenticationUrl = `https://api.twitter.com/oauth/authenticate?oauth_token=${data.oauth_token}&force_login=true`
      const popup = openPopup()
      popup.location = authenticationUrl
    } catch (error) {}
  }

  const openPopup = () => {
    const w = 600
    const h = 400
    const left = window.screen.width / 2 - w / 2
    const top = window.screen.height / 2 - h / 2

    return window.open(
      '',
      '',
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
        w +
        ', height=' +
        h +
        ', top=' +
        top +
        ', left=' +
        left
    )
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <FontAwesomeIcon className={styles.icon} icon={faTwitter} />

      <br />

      {expired && <TokenExpired />}

      <div className={styles.buttons}>
        <Button
          label={connected ? 'Edit Connection' : 'Connect Account'}
          loading={loading}
          onClick={getRequestToken.bind(this)}
        />
      </div>
    </div>
  )
}

export default Login
