import React from 'react'
import styles from './Button.module.scss'

function Button({ label, onClick, onClickArgs, disabled, loading, style, type = '' }) {
  const handleClick = () => {
    if (onClick) {
      onClick(onClickArgs || null)
    }
  }

  return (
    <button
      style={style}
      className={`${styles.button} ${type}`}
      onClick={handleClick}
      disabled={disabled || loading}>
      {!loading && label}
      {loading && (
        <div>
          {label}
          <div className={styles.loader}>
            <img alt='loader' height='20' src='/loader.svg' />
          </div>
        </div>
      )}
    </button>
  )
}

Button.defaultProps = {
  style: {},
}

export default Button
