import { API } from 'aws-amplify'
import BlockArea from 'components/block-area/BlockArea'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import BlockItem from 'components/block-area/BlockItem'
import LineChart from 'components/charts/LineChart'
import Loader from 'components/loader/Loader'
import MessageBox from 'components/message-box/MessageBox'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import styles from './Overview.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons'

function Overview() {
  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [analytics, setAnalytics] = useState([])

  const fetchAnalytics = async () => {
    return await API.get('socialmedia', '/facebook/analytics/overview')
  }

  useEffect(() => {
    const fetchData = async () => {
      const analytics = await fetchAnalytics()
      setAnalytics(analytics)
      setLoading(false)
    }

    fetchData()
  }, [])

  return (
    <div>
      <MessageBox
        content={`${
          user.firstNsme ? 'Hi ' + user.firstName : 'Hi'
        }, see some insights into your Facebook data below`}
      />

      <h1>
        <FontAwesomeIcon className={styles.headerIcon} icon={faFacebookSquare} /> Page
        Analytics
      </h1>

      {loading && <Loader />}

      {!loading && analytics.length === 0 && (
        <div>
          <br />
          <i>You'll need to connect some Facebook pages to see analytics</i>
        </div>
      )}

      {analytics.map((page, index) => (
        <div className={styles.area}>
          <br />
          <h2>{page.pageName}</h2>
          <BlockArea title=''>
            <BlockItem key='likes' label={page.data.likes.title} type='bg-purple'>
              <div className={styles.fans}>
                {page.data.likes.value}
                <FontAwesomeIcon className={styles.icon} icon={faThumbsUp} />
              </div>

              <div>
                <small>The total number of people who have liked your Page</small>
              </div>
            </BlockItem>

            <BlockItem
              key='page-impressions'
              label={page.data.page_impressions.title}
              type='bg-blue'>
              <div className={styles.chart}>
                <LineChart
                  data={[
                    {
                      id: 'Page Impressions',
                      data: page.data.page_impressions.values.map(item => {
                        return {
                          x: item.end_time,
                          y: item.value,
                        }
                      }),
                    },
                  ]}
                  colors={['#2da0f9']}
                  tooltipX='Date'
                  tooltipY='Total Impressions'
                />
              </div>

              <div>
                <small>
                  The number of times any content from your Page or about your Page
                  entered a person's screen. This includes posts, stories, check-ins, ads,
                  social information from people who interact with your Page and more.
                </small>
              </div>
            </BlockItem>

            <BlockItem
              key='page-post-impressions'
              label={page.data.page_posts_impressions.title}
              type='bg-green'>
              <div className={styles.chart}>
                <LineChart
                  data={[
                    {
                      id: 'Page Post Impressions',
                      data: page.data.page_posts_impressions.values.map(item => {
                        return {
                          x: item.end_time,
                          y: item.value,
                        }
                      }),
                    },
                  ]}
                  colors={['#50d168']}
                  tooltipX='Date'
                  tooltipY='Post Impressions'
                />
              </div>

              <div>
                <small>
                  The number of times your Page's posts entered a person's screen. Posts
                  include statuses, photos, links, videos and more
                </small>
              </div>
            </BlockItem>

            <BlockItem
              key='page-engaged-users'
              label={page.data.page_engaged_users.title}
              type='bg-orange'>
              <div className={styles.chart}>
                <LineChart
                  data={[
                    {
                      id: 'Page Engaged Users',
                      data: page.data.page_engaged_users.values.map(item => {
                        return {
                          x: item.end_time,
                          y: item.value,
                        }
                      }),
                    },
                  ]}
                  colors={['#ff9800']}
                  tooltipX='Date'
                  tooltipY='Engaged Users'
                />
              </div>

              <div>
                <small>
                  The number of people who engaged with your Page. Engagement includes any
                  click or story created.
                </small>
              </div>
            </BlockItem>

            <BlockItem
              key='page-post-engaged-users'
              label={page.data.page_post_engagements.title}
              type='bg-yellow'>
              <div className={styles.chart}>
                <LineChart
                  data={[
                    {
                      id: 'Page Engaged Users',
                      data: page.data.page_post_engagements.values.map(item => {
                        return {
                          x: item.end_time,
                          y: item.value,
                        }
                      }),
                    },
                  ]}
                  colors={['#f5d327']}
                  tooltipX='Date'
                  tooltipY='Post Engagements'
                />
              </div>

              <div>
                <small>{page.data.page_post_engagements.description}</small>
              </div>
            </BlockItem>
          </BlockArea>
        </div>
      ))}
    </div>
  )
}

export default Overview
