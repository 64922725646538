import React from 'react'
import Loader from 'components/loader/Loader'
import styles from './BlockArea.module.scss'

function BlockArea({ title, loading, cols = 2, ...props }) {
  return (
    <div>
      {title && (<h2 className={styles.header}>{title}</h2>)}
      <div className={styles.container}>
        {loading && <Loader />}
        <div className={`${styles.items} ${styles[`cols${cols}`]}`}>{props.children}</div>
      </div>
    </div>
  )
}

export default BlockArea
