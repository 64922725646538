import { API } from 'aws-amplify'
import Alert from 'components/alert/Alert'
import BlockArea from 'components/block-area/BlockArea'
import BlockItem from 'components/block-area/BlockItem'
import Button from 'components/button/Button'
import FormBody from 'components/form/form-body/FormBody'
import FormRow from 'components/form/form-row/FormRow'
import Label from 'components/form/label/Label'
import TextAreaInput from 'components/form/text-area-input/TextAreaInput'
import TextInput from 'components/form/text-input/TextInput'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

function Form(props) {
  const { id } = useParams()
  const [initialLoad, setInitialLoad] = useState(id ? true : false)
  const [loading, setLoading] = useState(false)
  const [alertSettings, setAlertSettings] = useState({
    show: false,
    message: '',
    type: 'info',
  })
  const [values, setValues] = useState({
    name: { value: '', error: '' },
    content: { value: '', error: '' },
  })

  useEffect(() => {
    const fetchData = async () => {
      const data = await API.get('tips', `/${id}`)
      const dataValues = {
        name: { value: data.name },
        content: { value: data.content },
      }
      setValues(dataValues)
      setInitialLoad(false)
    }

    if (id !== undefined) {
      fetchData()
    }
  }, [id, setValues])

  const valuesOnly = () => {
    return {
      name: values.name.value,
      content: values.content.value,
    }
  }

  const validateForm = async () => {
    try {
      await Yup.object()
        .shape({
          name: Yup.string().min(2).required(),
          content: Yup.string().min(2).required(),
        })
        .validate(valuesOnly(), { abortEarly: false })

      return true
    } catch (error) {
      const errors = {}
      error.inner.map(item => {
        var words = item.message.replace(/([A-Z])/g, ' $1')
        return (errors[item.path] = {
          error: words.charAt(0).toUpperCase() + words.slice(1),
        })
      })
      setValues({ ...values, ...errors })

      return false
    }
  }

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (await validateForm()) {
      setLoading(true)
      id ? await updateProcess() : await createProcess()
      setLoading(false)
    }
  }

  const createProcess = async () => {
    try {
      await API.post('tips', '', {
        body: valuesOnly(),
      })

      props.history.push({
        pathname: '/social-tips',
        state: {
          type: 'success',
          message: 'Successfully created social tip',
        },
      })
    } catch (error) {
      setAlertSettings({
        show: true,
        type: 'error',
        message:
          error.response.data.message || 'There was an error creating the social tip',
      })
    }
  }

  const updateProcess = async () => {
    try {
      await API.put('tips', `/${id}`, {
        body: valuesOnly(),
      })

      props.history.push({
        pathname: '/social-tips',
        state: {
          type: 'success',
          message: 'Successfully updated social tip',
        },
      })
    } catch (error) {
      setAlertSettings({
        show: true,
        type: 'error',
        message:
          error.response.data.message || 'There was an error updating the social tip',
      })
    }
  }

  return (
    <div>
      {alertSettings.show && (
        <Alert type={alertSettings.type}>{alertSettings.message}</Alert>
      )}

      <BlockArea cols='1'>
        {!initialLoad && (
          <BlockItem
            label={id ? 'Update Social Tip' : 'Create Social Tip'}
            type='bg-purple'>
            <form onSubmit={handleSubmit}>
              <FormBody>
                <FormRow>
                  <Label text='Name' />
                  <TextInput
                    name='name'
                    type='input'
                    value={values.name.value}
                    error={values.name.error}
                    onChange={handleChange.bind(this)}
                  />
                </FormRow>

                <FormRow>
                  <Label text='Content' />
                  <TextAreaInput
                    name='content'
                    value={values.content.value}
                    error={values.content.error}
                    onChange={handleChange.bind(this)}
                  />
                </FormRow>
              </FormBody>

              <div>
                <Button label={id ? 'Update' : 'Create'} loading={loading} />
              </div>
            </form>
          </BlockItem>
        )}
      </BlockArea>
    </div>
  )
}

export default Form
