import PropTypes from 'prop-types'
import React from 'react'
import ReactSwitch from 'react-switch'

function Switch({ checked, onChange }) {
  return (
    <ReactSwitch
      onChange={onChange}
      onColor='#50d168'
      checkedIcon={false}
      uncheckedIcon={false}
      checked={checked}
    />
  )
}

Switch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

Switch.defaultProps = {
  checked: false,
}

export default Switch
