import { faLink, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from 'aws-amplify'
import Alert from 'components/alert/Alert'
import DeleteButton from 'components/delete-button/DeleteButton'
import IconButton from 'components/icon-button/IconButton'
import MessageBox from 'components/message-box/MessageBox'
import Table from 'components/table/Table'
import TableBody from 'components/table/TableBody'
import TableCell from 'components/table/TableCell'
import TableHead from 'components/table/TableHead'
import TableRow from 'components/table/TableRow'
import React, { useEffect, useState } from 'react'
import styles from './ManageLinkedIn.module.scss'

function ManageLinkedIn(props) {
  const [loading, setLoading] = useState(false)
  const [connections, setConnections] = useState({
    items: [],
  })

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const data = await API.get('connections', '/linkedin/orgs')
      setConnections(data)
      setLoading(false)
    }

    fetchData()
  }, [])

  const handleDelete = async id => {
    setLoading(true)
    await API.del('tokens', `/linkedin/${id}`)
    const activeConnections = connections.items.map(item => {
      if (item.id === id) {
        const o = Object.assign({}, item)
        o.connected = false
        return o
      }
      return item
    })
    connections.items = activeConnections
    setConnections(connections)
    setLoading(false)

    if (connections.items.length <= 0) {
      props.history.push({ pathname: '/social-media-connect' })
    }
  }

  const handleConnect = async id => {
    setLoading(true)
    await API.post('connections', '/linkedin/orgs', {
      body: {
        id: id,
      },
    })
    const activeConnections = connections.items.map(item => {
      if (item.id === id) {
        const o = Object.assign({}, item)
        o.connected = true
        return o
      }
      return item
    })
    connections.items = activeConnections
    setConnections(connections)
    setLoading(false)
    setLoading(false)
  }

  return (
    <div>
      <MessageBox
        content={
          <>
            Connect LinkedIn Organisations with BeSo Social using the{' '}
            <FontAwesomeIcon icon={faLink} /> icon or disconnect with the{' '}
            <FontAwesomeIcon icon={faTrash} /> icon
          </>
        }
      />

      {props.location.state && (
        <Alert type={props.location.state.type}>{props.location.state.message}</Alert>
      )}

      {connections.items.length === 0 && (
        <div>
          <Table title='LinkedIn Organisations' loading={loading}>
            No organisations found. You will need to be admin on LinkedIn to be able to
            connect them.
          </Table>
        </div>
      )}

      {connections.items.length > 0 && (
        <Table title='LinkedIn Organisations' loading={loading}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {connections.items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.connected ? 'Connected' : 'Not Connected'}</TableCell>
                <TableCell>
                  <div className={styles.actions}>
                    {!item.connected && (
                      <IconButton
                        text={`Are you sure you want to connect ${item.name}, scheduled posts will be shared to this organisation`}
                        onClickId={item.id}
                        onClick={handleConnect.bind(this)}
                      />
                    )}

                    {item.connected && (
                      <DeleteButton
                        text={`Are you sure you want to disconnect ${item.name}, scheduled posts will not be shared on this organisation`}
                        deleteId={item.id}
                        onDelete={handleDelete.bind(this)}
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  )
}

export default ManageLinkedIn
