import React from 'react'
import styles from './TextInput.module.scss'

function TextInput(props) {
  return (
    <div>
      <input
        className={styles.input}
        type={props.type}
        name={props.name}
        defaultValue={props.value}
        onChange={props.onChange}
      />

      {props.error && <div className={styles.error}>{props.error}</div>}
    </div>
  )
}

export default TextInput
