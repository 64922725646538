import React, { useState } from 'react'

const SearchContext = React.createContext()
const WithSearch = ({ children }) => {
  const [displaySearch, setDisplaySearch] = useState(false)
  const [query, setQuery] = useState('')

  const defaultContext = {
    displaySearch,
    setDisplaySearch,
    query,
    setQuery,
  }
  return (
    <SearchContext.Provider value={defaultContext}>{children}</SearchContext.Provider>
  )
}

export { SearchContext }
export default WithSearch
