import { API, Storage } from 'aws-amplify'
import Alert from 'components/alert/Alert'
import BlockArea from 'components/block-area/BlockArea'
import BlockItem from 'components/block-area/BlockItem'
import Button from 'components/button/Button'
import FormBody from 'components/form/form-body/FormBody'
import FormRow from 'components/form/form-row/FormRow'
import Label from 'components/form/label/Label'
import TextInput from 'components/form/text-input/TextInput'
import ImageCropper from 'components/ImageCropper'
import MessageBox from 'components/message-box/MessageBox'
import PostContent, { validatePostContent } from 'components/post-content/PostContent'
import config from 'config'
import { validateImageSize } from 'libs/image-size-checker'
import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'

const dataURItoBlob = async dataURI => {
  const entity = await fetch(dataURI)
  return await entity.blob()
}

function Form(props) {
  const [loading, setLoading] = useState(false)
  const [alertSettings, setAlertSettings] = useState({
    show: false,
    message: '',
    type: 'info',
  })
  const [values, setValues] = useState({
    content: { value: '', error: '' },
    shortContent: { value: '', error: '' },
    imageUrl: { value: '', error: '' },
  })
  const [imageBase64, setImageBase64] = useState()
  const [editedImageBase64, setEditedImageBase64] = useState()
  const { addToast } = useToasts()

  const validateForm = async () => {
    return await validatePostContent(values, setValues)
  }

  const validateImage = async (file = '') => {
    const name = 'imageUrl'

    if (!file) {
      file = values.imageUrl.value
    }

    const maxSize = config.POST_IMG_MAX_SIZE
    const types = config.POST_IMG_TYPES

    if (!file) {
      setValues({ ...values, [name]: { error: `An image is required` } })
      return false
    }

    if (!types.includes(file.type)) {
      setValues({ ...values, [name]: { error: `Invalid image type` } })
      return false
    }

    if (file.size > maxSize) {
      setValues({
        ...values,
        [name]: { error: `Image is too big (${maxSize / 1000000}MB limit)` },
      })
      return false
    }

    const validSize = await validateImageSize(file)
    if (!validSize) {
      setValues({
        ...values,
        [name]: {
          error: `Image must be at least ${config.POST_IMG_WIDTH} x ${config.POST_IMG_HEIGHT}`,
        },
      })
      return false
    }

    return true
  }

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleFileChange = async event => {
    const { name } = event.target
    const file = event.target.files[0]

    const valid = await validateImage(file)
    if (!valid) {
      setImageBase64('')
      return false
    } else {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      setValues({ ...values, [name]: { value: file } })
      reader.onloadend = () => {
        setImageBase64(reader.result)
      }
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()

    const validForm = await validateForm()
    const validImage = await validateImage()

    if (validForm && validImage) {
      setLoading(true)
      const success = await createProcess()

      if (success) {
        addToast('Successfully add custom post to pending schedule', {
          appearance: 'success',
          autoDismiss: true,
        })

        props.history.push({ pathname: '/schedule' })
      } else {
        setAlertSettings({
          show: true,
          type: 'error',
          message: 'There was an error adding custom post to schedule',
        })
        setLoading(false)
      }
    }
  }

  const uploadImage = async () => {
    const file = values.imageUrl.value
    const editedFile = await dataURItoBlob(editedImageBase64)
    const safeName = file.name.replace(/ /g, '_').replace(/\.[^/.]+$/, '') // spaces to underscore and remove file extension
    const name = `posts/CIma${Date.now()}-${safeName}.jpg`
    const image = await Storage.put(name, editedFile, {
      contentType: editedFile.type,
    })
    return image.key
  }

  const createProcess = async () => {
    try {
      const image = await uploadImage()
      await API.post('schedule', '', {
        body: {
          postId: '',
          content: values.content.value,
          shortContent: values.shortContent.value,
          imageUrl: image,
        },
      })

      return true
    } catch (error) {
      return false
    }
  }

  return (
    <div>
      <MessageBox content='You can add anything you want to your schedule, simply write your content and upload an image below.' />

      <h2>Custom Post</h2>
      <p>
        <i>
          Custom posts will be added to your pending schedule, once created you'll be
          redirected so that you can set a date and time to publish as with any other
          post.
        </i>
      </p>

      {alertSettings.show && (
        <Alert type={alertSettings.type}>{alertSettings.message}</Alert>
      )}

      <BlockArea cols='1'>
        <BlockItem label='Details' type='bg-purple'>
          <form onSubmit={handleSubmit}>
            <FormBody>
              <PostContent values={values} handleChange={handleChange} />

              <FormRow>
                <Label text='Image' />
                <TextInput
                  name='imageUrl'
                  type='file'
                  error={values.imageUrl.error}
                  onChange={handleFileChange.bind(this)}
                />
                <p>
                  Your image must be at least {config.POST_IMG_WIDTH} x{' '}
                  {config.POST_IMG_HEIGHT}
                </p>
              </FormRow>

              <ImageCropper imageBase64={imageBase64} onChange={setEditedImageBase64} />
            </FormBody>

            <div>
              <Button label='Add to Schedule' loading={loading} />
            </div>
          </form>
        </BlockItem>
      </BlockArea>
    </div>
  )
}

export default Form
