import config from 'config'

export function validateImageSize(file) {
  return new Promise(resolve => {
    const img = new Image()
    img.src = window.URL.createObjectURL(file)
    img.onload = async () => {
      if (img.width < config.POST_IMG_WIDTH || img.height < config.POST_IMG_HEIGHT) {
        resolve(false)
      } else {
        resolve(true)
      }
    }
  })
}
