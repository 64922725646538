import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { API } from 'aws-amplify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons'
import Alert from 'components/alert/Alert'
import Button from 'components/button/Button'
import DeleteButton from 'components/delete-button/DeleteButton'
import Table from 'components/table/Table'
import TableHead from 'components/table/TableHead'
import TableBody from 'components/table/TableBody'
import TableRow from 'components/table/TableRow'
import TableCell from 'components/table/TableCell'
import styles from './Users.module.scss'

function Users(props) {
  const [loading, setLoading] = useState(false)
  const [lastDisabled, setLastDisabled] = useState(true)
  const [nextDisabled, setNextDisabled] = useState(true)
  const [users, setUsers] = useState({
    items: [],
    count: null,
    limit: null,
    last: null,
  })
  const [pages, setPages] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      pages.push('')
      setPages(pages)
      const data = await API.get('users', '')
      setUsers(data)
      setNextDisabled(data.last === null)
      setLoading(false)
    }

    fetchData()
  }, [pages])

  const handleNext = async () => {
    setLoading(true)
    pages.push(users.last)
    setPages(pages)
    const data = await API.get('users', `?last=${users.last}`)
    setUsers(data)
    setNextDisabled(data.last === null)
    setLastDisabled(false)
    setLoading(false)
  }

  const handleLast = async () => {
    setLoading(true)
    const last = pages.slice(pages.length - 2)[0]
    pages.splice(pages.length - 1, 1)
    setPages(pages)
    const data = await API.get('users', `?last=${last}`)
    setUsers(data)
    setNextDisabled(data.last === null)
    setLastDisabled(pages.length <= 1)
    setLoading(false)
  }

  const handleDelete = async id => {
    setLoading(true)
    await API.del('users', `/${id}`)
    const activeUsers = users.items.filter(item => {
      return item.id !== id
    })
    users.items = activeUsers
    setUsers(users)
    setLoading(false)
  }

  return (
    <div>
      {props.location.state && (
        <Alert type={props.location.state.type}>{props.location.state.message}</Alert>
      )}

      {users.items.length === 0 && (
        <>
          <h2 className={styles.header}>Users</h2>
          <Table loading={loading}>
            <div className={`text-center`}>
              <br />
              <i>No users found</i>
              <br />
            </div>
          </Table>
        </>
      )}

      {users.items.length > 0 && (
        <>
          <h2 className={styles.header}>Users</h2>

          <Table loading={loading}>
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.firstName}</TableCell>
                  <TableCell>{item.lastName}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>
                    <div className={styles.actions}>
                      <NavLink to={`/users/update/${item.id}`} exact>
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </NavLink>

                      <DeleteButton
                        text={`Are you sure you want to delete ${item.firstName} ${item.lastName}`}
                        deleteId={item.id}
                        onDelete={handleDelete.bind(this)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}

      <br />

      <div className='text-center'>
        {users.items.length > 0 && (
          <Button
            label={<FontAwesomeIcon icon={faChevronLeft} />}
            disabled={lastDisabled || loading}
            onClick={handleLast.bind(this)}
          />
        )}
        <NavLink to='/users/create' exact>
          <Button label='Create User' />
        </NavLink>
        {users.items.length > 0 && (
          <Button
            label={<FontAwesomeIcon icon={faChevronRight} />}
            disabled={nextDisabled || loading}
            onClick={handleNext.bind(this)}
          />
        )}
      </div>
    </div>
  )
}

export default Users
