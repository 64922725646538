import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from 'aws-amplify'
import BlockArea from 'components/block-area/BlockArea'
import BlockItem from 'components/block-area/BlockItem'
import DeleteButton from 'components/delete-button/DeleteButton'
import MessageBox from 'components/message-box/MessageBox'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import styles from './Tips.module.scss'
import Button from 'components/button/Button'

function Tips(props) {
  const { user } = useContext(AuthContext)
  const [tips, setTips] = useState({
    items: [],
    count: null,
    limit: null,
    last: null,
  })
  const { addToast } = useToasts()

  useEffect(() => {
    const fetchData = async () => {
      const data = await API.get('tips', '')
      setTips(data)
    }

    fetchData()

    if (props.location.state) {
      addToast(props.location.state.message, {
        appearance: props.location.state.type,
        autoDismiss: true,
      })
    }
  }, [addToast, props.location.state])

  const headerType = index => {
    const mod = index % 5

    if (mod % 5 === 0) {
      return 'border-yellow'
    } else if (mod % 4 === 0) {
      return 'border-green'
    } else if (mod % 3 === 0) {
      return 'border-orange'
    } else if (mod % 2 === 0) {
      return 'border-blue'
    }

    return 'border-purple'
  }

  const handleDelete = async id => {
    await API.del('tips', `/${id}`)
    const displayItems = tips.items.filter(item => {
      return item.id !== id
    })
    tips.items = displayItems
    setTips(tips)
    addToast('Successfully deleted tip', {
      appearance: 'success',
      autoDismiss: true,
    })
  }

  return (
    <div>
      <MessageBox
        content={`${
          user.firstNsme ? 'Hi ' + user.firstName : 'Hi'
        }, did you know that you can edit posts before they go out to give them that personal touch`}
      />

      <BlockArea title='Social Tips' loading={tips.count === null}>
        {tips.items.map((item, index) => (
          <BlockItem
            key={item.id}
            label={
              <>
                {item.name}
                {user.role === 'admin' && (
                  <span className={styles.adminActions}>
                    <Link to={`/social-tips/update/${item.id}`}>
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </Link>

                    <DeleteButton
                      text={`Are you sure you want to delete ${item.name}`}
                      deleteId={item.id}
                      onDelete={handleDelete}
                    />
                  </span>
                )}
              </>
            }
            type={headerType(index + 1) + ' border-top'}>
            {item.content}
          </BlockItem>
        ))}
      </BlockArea>

      {user.role === 'admin' && (
        <Link to='/social-tips/create' exact>
          <Button label='Create Tip' />
        </Link>
      )}
    </div>
  )
}

export default Tips
