import BlockArea from 'components/block-area/BlockArea'
import BlockItem from 'components/block-area/BlockItem'
import FacebookLogin from 'components/facebook/login/Login'
import LinkedInLogin from 'components/linkedin/login/Login'
import MessageBox from 'components/message-box/MessageBox'
import TwitterLogin from 'components/twitter/login/Login'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext } from 'react'
import styles from './SocialMediaConnect.module.scss'

function SocialMediaConnect() {
  const { user } = useContext(AuthContext)

  return (
    <div>
      <MessageBox
        content={`${
          user.firstNsme ? 'Hi ' + user.firstName : 'Hi'
        }, connect your social media accounts to get started.`}
      />

      <BlockArea title='Connect a social network' cols='3'>
        <BlockItem>
          <div className='text-center'>
            <FacebookLogin />
          </div>

          <div className={styles.instructions}>
            <p>
              <strong>How to Connect</strong>
            </p>
            <p>Use the Connect Page button above to connect with Facebook.</p>
            <p>
              A popup window should appear asking you to login, or continue as a specific
              user if you are already logged into Facebook
            </p>
            <p>
              From within the Facebook popup you can then select which pages to connect
              and post with BeSo Social
            </p>
          </div>
        </BlockItem>
        <BlockItem>
          <div className='text-center'>
            <LinkedInLogin />
          </div>

          <div className={styles.instructions}>
            <p>
              <strong>How to Connect</strong>
            </p>
            <p>Use the Connect Profile button above to connect with LinkedIn.</p>
            <p>A popup window should appear asking you to login.</p>
            <p>
              Once you have connected your profile, a Manage Organisations button should
              show above. Click this to view all organisations that your profile is linked
              to. From within this page you can connect or disconnect individual
              organisations from BeSo Social. If connected then scheduled posts will be
              publish to that organisation.
            </p>
          </div>
        </BlockItem>
        <BlockItem>
          <div className='text-center'>
            <TwitterLogin />
          </div>

          <div className={styles.instructions}>
            <p>
              <strong>How to Connect</strong>
            </p>
            <p>Use the Connect Account button above to connect with Twitter.</p>
            <p>A popup window should appear asking you to login.</p>
            <p>Once connected, scheduled posts will be published to that account.</p>
          </div>
        </BlockItem>
      </BlockArea>
    </div>
  )
}

export default SocialMediaConnect
