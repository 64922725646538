import PropTypes from 'prop-types'
import React from 'react'
import ReactModal from 'react-modal'
import './Modal.css'

function Modal({ isOpen, onRequestClose, children }) {
  const customStyles = {
    content: {
      top: '30%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  ReactModal.setAppElement('#root')

  return (
    <ReactModal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
      {children}
    </ReactModal>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  children: PropTypes.array,
}

Modal.defaultProps = {
  isOpen: false,
}

export default Modal
