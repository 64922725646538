import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from 'aws-amplify'
import Button from 'components/button/Button'
import DeleteButton from 'components/delete-button/DeleteButton'
import Loader from 'components/loader/Loader'
import MessageBox from 'components/message-box/MessageBox'
import { AuthContext } from 'contexts/AuthContext'
import { SearchContext } from 'contexts/SearchContext'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import PostItem from './PostItem'
import styles from './Posts.module.scss'
import Search from './Search'
import { useToasts } from 'react-toast-notifications'

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

function Posts(props) {
  const { categoryId, categoryName } = useParams()
  const { user } = useContext(AuthContext)
  const { query, setQuery, setDisplaySearch } = useContext(SearchContext)
  const [posts, setPosts] = useState({
    items: [],
    count: null,
    limit: null,
    last: null,
  })
  const [moreLoading, setMoreLoading] = useState(false)
  const { addToast } = useToasts()

  const fetchPosts = async (params = {}) => {
    let url = ``

    if (categoryId) {
      url = `${url}/category/${categoryId}`
    }

    var query = Object.keys(params)
      .map(k => k + '=' + params[k])
      .join('&')

    if (query) {
      url = `${url}?${query}`
    }

    return await API.get('posts', url)
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchPosts({ limit: 25 })
      data.items = shuffle(data.items)
      setPosts(data)
    }

    if (props.location.state) {
      addToast(props.location.state.message, {
        appearance: props.location.state.type,
        autoDismiss: true,
      })
    }

    fetchData()
    setDisplaySearch(true)

    return () => {
      setDisplaySearch(false)
      setQuery('')
    }

    // eslint-disable-next-line
  }, [categoryId, addToast, props.location.state])

  const handleMore = async () => {
    setMoreLoading(true)
    const data = await fetchPosts({ limit: 5, last: posts.last })
    data.items = posts.items.concat(shuffle(data.items))
    setPosts(data)
    setMoreLoading(false)
  }

  const handleDeleteCategory = async () => {
    await API.del('categories', `/${categoryId}`)
    props.history.push({
      pathname: '/categories',
      state: {
        type: 'success',
        message: 'Successfully deleted category',
      },
    })
  }

  if (query.length > 0) {
    return <Search />
  }

  return (
    <div>
      <MessageBox
        content={`${
          user.firstNsme ? 'Hi ' + user.firstName : 'Hi'
        }, how's the post search going? Let us know if you have any suggestions!`}
      />

      <h2 className={styles.header}>
        {categoryName ? `${categoryName.replace('::', '/')} Posts` : 'All Posts'}

        {user.role === 'admin' && (
          <span className={styles.adminActions}>
            <Link to={`/social-posts/create`}>
              <FontAwesomeIcon icon={faPlus} />
            </Link>

            {categoryName && (
              <>
                <Link to={`/categories/update/${categoryId}`}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Link>

                {posts.count === 0 && (
                  <DeleteButton
                    text={`Are you sure you want to delete ${categoryName}`}
                    deleteId={categoryId}
                    onDelete={handleDeleteCategory.bind(this)}
                  />
                )}
              </>
            )}
          </span>
        )}
      </h2>

      {posts.count === null && <Loader />}

      <div className={styles.posts}>
        {posts.count === 0 && (
          <div>
            <br />
            <br />
            <i>No posts found</i>
          </div>
        )}
        {posts.items.map(item => (
          <PostItem key={item.id} item={item} />
        ))}
      </div>

      <div className='text-center'>
        {posts.last && (
          <Button
            label='Show More'
            loading={moreLoading}
            onClick={handleMore.bind(this)}
          />
        )}
      </div>
    </div>
  )
}

export default Posts
