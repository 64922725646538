import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from 'aws-amplify'
import Button from 'components/button/Button'
import Loader from 'components/loader/Loader'
import TokenExpired from 'components/token-expired/TokenExpired'
import config from 'config'
import React, { useEffect, useState } from 'react'
import styles from './Login.module.scss'

function FacebookLogin(props) {
  const [sdkLoaded, setSdkLoaded] = useState(false)
  const [connected, setConnected] = useState(false)
  const [expired, setExpired] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const res = await API.get('tokens', '/facebook')
        setConnected(true)
        setExpired(res.expired)
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
    fetchToken()

    if (!sdkLoaded) {
      facebookInit()
      facebookSdk()

      const initializeFacebookLogin = () => {
        setSdkLoaded(true)
        checkLoginStatus()
      }

      const checkLoginStatus = () => {
        window.FB.getLoginStatus(facebookLoginHandler)
      }

      document.addEventListener('FBObjectReady', initializeFacebookLogin)
    }
  }, [sdkLoaded])

  const facebookInit = () => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: config.FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v2.11',
      })

      // Broadcast an event when FB object is ready
      var fbInitEvent = new Event('FBObjectReady')
      document.dispatchEvent(fbInitEvent)
    }
  }

  const facebookSdk = () => {
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }

  const facebookLogin = () => {
    if (!window.FB) return

    window.FB.getLoginStatus(response => {
      window.FB.login(facebookLoginHandler, {
        scope: 'pages_manage_posts, pages_read_engagement, read_insights',
      })
    })
  }

  const facebookLoginHandler = async response => {
    if (response.status === 'connected') {
      await API.put('tokens', '/facebook', {
        body: {
          provider: 'FACEBOOK',
          token: response.authResponse.accessToken,
        },
      })
      setConnected(true)
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <FontAwesomeIcon className={styles.icon} icon={faFacebookSquare} />

      <br />

      {expired && <TokenExpired />}

      <div className={styles.buttons}>
        <Button
          label={connected ? 'Edit Connection' : 'Connect Page'}
          loading={loading}
          onClick={facebookLogin.bind(this)}
        />
      </div>
    </div>
  )
}

export default FacebookLogin
