import { API } from 'aws-amplify'
import Alert from 'components/alert/Alert'
import BlockArea from 'components/block-area/BlockArea'
import BlockItem from 'components/block-area/BlockItem'
import Button from 'components/button/Button'
import FormBody from 'components/form/form-body/FormBody'
import FormRow from 'components/form/form-row/FormRow'
import Label from 'components/form/label/Label'
import TextInput from 'components/form/text-input/TextInput'
import MessageBox from 'components/message-box/MessageBox'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useToasts } from 'react-toast-notifications'

function Create(props) {
  const { id } = useParams()
  const [initialLoad, setInitialLoad] = useState(id ? true : false)
  const [loading, setLoading] = useState(false)
  const [alertSettings, setAlertSettings] = useState({
    show: false,
    message: '',
    type: 'info',
  })
  const [values, setValues] = useState({
    firstName: { value: '', error: '' },
    lastName: { value: '', error: '' },
    email: { value: '', error: '' },
    password: { value: '', error: '' },
  })
  const { addToast } = useToasts()

  useEffect(() => {
    const fetchData = async () => {
      const data = await API.get('users', `/${id}`)
      const dataValues = {
        firstName: { value: data.firstName },
        lastName: { value: data.lastName },
        email: { value: data.email },
        password: { value: '' },
      }
      setValues(dataValues)
      setInitialLoad(false)
    }

    if (id !== undefined) {
      fetchData()
    }
  }, [id, setValues])

  const valuesOnly = () => {
    return {
      email: values.email.value,
      firstName: values.firstName.value,
      lastName: values.lastName.value,
      password: values.password.value,
    }
  }

  const validateForm = async () => {
    try {
      const rules = {
        firstName: Yup.string().min(2).required(),
        lastName: Yup.string().min(2).required(),
        email: Yup.string().email().required(),
      }

      if (!id || values.password.value.length > 0) {
        rules.password = Yup.string().optional().min(8)
      }

      await Yup.object().shape(rules).validate(valuesOnly(), { abortEarly: false })

      return true
    } catch (error) {
      const errors = {}
      error.inner.map(item => {
        var words = item.message.replace(/([A-Z])/g, ' $1')
        return (errors[item.path] = {
          error: words.charAt(0).toUpperCase() + words.slice(1),
        })
      })
      setValues({ ...values, ...errors })

      return false
    }
  }

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value } })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (await validateForm()) {
      setLoading(true)
      id ? await updateProcess() : await createProcess()
      setLoading(false)
    }
  }

  const createProcess = async () => {
    try {
      await API.post('users', '', {
        body: valuesOnly(),
      })

      addToast('Successfully created user.', {
        appearance: 'success',
        autoDismiss: true,
      })

      props.history.push({ pathname: '/users' })
    } catch (error) {
      setAlertSettings({
        show: true,
        type: 'error',
        message: error.response.data.message || 'There was an error creating the user',
      })
      setLoading(false)
    }
  }

  const updateProcess = async () => {
    try {
      await API.put('users', `/${id}`, {
        body: valuesOnly(),
      })

      addToast('Successfully updated user.', {
        appearance: 'success',
        autoDismiss: true,
      })

      props.history.push({ pathname: '/users' })
    } catch (error) {
      setAlertSettings({
        show: true,
        type: 'error',
        message: error.response.data.message || 'There was an error updating the user',
      })
    }
  }

  return (
    <div>
      <MessageBox
        content={`Adding a user will allow them to login, link their social media accounts and share content.`}
      />
      {alertSettings.show && (
        <Alert type={alertSettings.type}>{alertSettings.message}</Alert>
      )}

      <BlockArea>
        {!initialLoad && (
          <BlockItem
            label={id ? 'Update User' : 'Create User'}
            type='bg-dark-blue border-only'>
            <form onSubmit={handleSubmit}>
              <FormBody>
                <FormRow>
                  <Label text='First Name' />
                  <TextInput
                    name='firstName'
                    value={values.firstName.value}
                    type='input'
                    error={values.firstName.error}
                    onChange={handleChange.bind(this)}
                  />
                </FormRow>

                <FormRow>
                  <Label text='Last Name' />
                  <TextInput
                    name='lastName'
                    type='input'
                    value={values.lastName.value}
                    error={values.lastName.error}
                    onChange={handleChange.bind(this)}
                  />
                </FormRow>

                <FormRow>
                  <Label text='Email' />
                  <TextInput
                    name='email'
                    value={values.email.value}
                    type='email'
                    error={values.email.error}
                    onChange={handleChange.bind(this)}
                  />
                </FormRow>

                <FormRow>
                  <Label text='Password' />
                  <TextInput
                    name='password'
                    type='password'
                    error={values.password.error}
                    onChange={handleChange.bind(this)}
                  />
                </FormRow>
              </FormBody>

              <div>
                <Button label={id ? 'Update' : 'Create'} loading={loading} />
              </div>
            </form>
          </BlockItem>
        )}
      </BlockArea>
    </div>
  )
}

export default Create
