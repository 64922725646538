import {
  faChevronLeft,
  faChevronRight,
  faPencilAlt,
  faPlayCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from 'aws-amplify'
import Alert from 'components/alert/Alert'
import Button from 'components/button/Button'
import DeleteButton from 'components/delete-button/DeleteButton'
import InfoModal from 'components/info-modal/InfoModal'
import Table from 'components/table/Table'
import TableBody from 'components/table/TableBody'
import TableCell from 'components/table/TableCell'
import TableCellActions from 'components/table/TableCellActions'
import TableHead from 'components/table/TableHead'
import TableRow from 'components/table/TableRow'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

const welcomeUrl = email => {
  return (
    window.location.protocol + '//' + window.location.host + '/welcome/' + encodeURIComponent(btoa(email))
  )
}

function Distributors(props) {
  const [loading, setLoading] = useState(false)
  const [lastDisabled, setLastDisabled] = useState(true)
  const [nextDisabled, setNextDisabled] = useState(true)
  const [users, setUsers] = useState({
    items: [],
    count: null,
    limit: null,
    last: null,
  })
  const [pages, setPages] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      pages.push('')
      setPages(pages)
      const data = await API.get('distributors', '')
      setUsers(data)
      setNextDisabled(data.last === null)
      setLoading(false)
    }

    fetchData()
  }, [pages])

  const handleNext = async () => {
    setLoading(true)
    pages.push(users.last)
    setPages(pages)
    const data = await API.get('distributors', `?last=${users.last}`)
    setUsers(data)
    setNextDisabled(data.last === null)
    setLastDisabled(false)
    setLoading(false)
  }

  const handleLast = async () => {
    setLoading(true)
    const last = pages.slice(pages.length - 2)[0]
    pages.splice(pages.length - 1, 1)
    setPages(pages)
    const data = await API.get('distributors', `?last=${last}`)
    setUsers(data)
    setNextDisabled(data.last === null)
    setLastDisabled(pages.length <= 1)
    setLoading(false)
  }

  const handleDelete = async id => {
    setLoading(true)
    await API.del('distributors', `/${id}`)
    const activeUsers = users.items.filter(item => {
      return item.id !== id
    })
    users.items = activeUsers
    setUsers(users)
    setLoading(false)
  }

  return (
    <div>
      {props.location.state && (
        <Alert type={props.location.state.type}>{props.location.state.message}</Alert>
      )}

      <Table title='Distributors' loading={loading}>
        <TableHead>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>User Limit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.items.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.firstName}</TableCell>
              <TableCell>{item.lastName}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{item.limit}</TableCell>
              <TableCell>
                <TableCellActions>
                  <InfoModal
                    icon={<FontAwesomeIcon icon={faPlayCircle} />}
                    text={
                      <>
                        <p>Welcome password setup link for this user.</p>
                        {welcomeUrl(item.email)}
                      </>
                    }
                  />

                  <NavLink to={`/distributors/update/${item.id}`} exact>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </NavLink>

                  <DeleteButton
                    text={`Are you sure you want to delete ${item.firstName} ${item.lastName}`}
                    deleteId={item.id}
                    onDelete={handleDelete.bind(this)}
                  />
                </TableCellActions>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <br />

      <div className='text-center'>
        <Button
          label={<FontAwesomeIcon icon={faChevronLeft} />}
          disabled={lastDisabled || loading}
          onClick={handleLast.bind(this)}
        />
        <NavLink to='/distributors/create' exact>
          <Button label='Create' />
        </NavLink>
        <Button
          label={<FontAwesomeIcon icon={faChevronRight} />}
          disabled={nextDisabled || loading}
          onClick={handleNext.bind(this)}
        />
      </div>
    </div>
  )
}

export default Distributors
