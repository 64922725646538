import React, { useEffect, useContext } from 'react'
import { Auth } from 'aws-amplify'
import { Redirect } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'

function Logout() {
  const { setAuthenticated } = useContext(AuthContext)

  useEffect(() => {
    Auth.signOut().then(() => {
      setAuthenticated(false)
    })
  })

  return (
    <Redirect to={`/login`} />
  )
}

export default Logout
