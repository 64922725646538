import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from 'aws-amplify'
import Badge from 'components/badge/Badge'
import Button from 'components/button/Button'
import DeleteButton from 'components/delete-button/DeleteButton'
import S3Image from 'components/s3-image/S3Image'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import styles from './PostItem.module.scss'

function PostItem(props) {
  const { user } = useContext(AuthContext)
  const [publishing, setPublishing] = useState(false)
  const [published, setPublished] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const { addToast } = useToasts()

  const handlePublish = async () => {
    setPublishing(true)
    await API.post('schedule', '', {
      body: {
        postId: props.item.id,
        content: props.item.content,
        shortContent: props.item.shortContent,
        imageUrl: props.item.imageUrl,
      },
    })

    setPublishing(false)
    setPublished(true)
    addToast(
      'Post added to your calendar, you can set a date and time from within the schedule area.',
      {
        appearance: 'success',
        autoDismiss: true,
      }
    )
  }

  const handleDelete = async () => {
    await API.del('posts', `/${props.item.id}`)
    addToast('Successfully deleted post', {
      appearance: 'success',
      autoDismiss: true,
    })
    setDeleted(true)
  }

  if (deleted) {
    return null
  }

  return (
    <div
      className={styles.item}
      style={{ borderColor: `#${props.item.categories[0].color}` }}>
      <div className={styles.headerRow}>
        <div>
          {props.item.categories.map(category => (
            <Badge key={category.id}>{category.name}</Badge>
          ))}
        </div>

        {user.role === 'admin' && (
          <div className={styles.adminActions}>
            <Link to={`/social-posts/update/${props.item.id}`}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </Link>

            <DeleteButton
              text={`Are you sure you want to delete this post?`}
              deleteId={props.item.id}
              onDelete={handleDelete}
            />
          </div>
        )}
      </div>

      <div className={styles.content}>
        <div>
          <div className={styles.body}>{props.item.content}</div>
        </div>
        <div className={styles.image}>
          <div className={`image-thumb`}>
            <S3Image path={props.item.imageUrl} height='110' width='180' />
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        {user.role !== 'admin' && (
          <Button
            disabled={published}
            loading={publishing}
            onClick={handlePublish.bind(this)}
          />
        )}
      </div>
    </div>
  )
}

export default PostItem
