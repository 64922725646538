import FormRow from 'components/form/form-row/FormRow'
import Label from 'components/form/label/Label'
import TextAreaInput from 'components/form/text-area-input/TextAreaInput'
import PropTypes from 'prop-types'
import React from 'react'
import * as Yup from 'yup'
import twttr from 'twitter-text'

const MAX_CONTENT_LENGTH = 700
const MAX_SHORT_CONTENT_LENGTH = 280

const getContentLength = content => {
  const parsed = twttr.parseTweet(twttr.htmlEscape(content))
  return parsed.weightedLength
}

function PostContent({ values, handleChange, alwaysShowShortContent }) {
  const getTwitterText = () => {
    if (getContentLength(values.content.value) > MAX_SHORT_CONTENT_LENGTH) {
      return (
        <>
          Twitter has a maximum character limit of {MAX_SHORT_CONTENT_LENGTH} characters,
          you will need to enter a shorter version for Twitter only below.
        </>
      )
    }

    return (
      <>
        You can enter a slightly different version for Twitter only below. if left blank
        the main content above will be used.
      </>
    )
  }

  const handleChangeContent = event => {
    if (getContentLength(event.target.value) <= MAX_CONTENT_LENGTH) {
      handleChange(event)
    }
  }

  const handleChangeShortContent = event => {
    if (getContentLength(event.target.value) <= MAX_SHORT_CONTENT_LENGTH) {
      handleChange(event)
    }
  }

  return (
    <>
      <FormRow>
        <Label
          text={`Content (${getContentLength(
            values.content.value
          )} of ${MAX_CONTENT_LENGTH})`}
        />
        <TextAreaInput
          name='content'
          value={values.content.value}
          type='input'
          rows={5}
          maxlength={MAX_CONTENT_LENGTH}
          error={values.content.error}
          onChange={handleChangeContent}
        />
      </FormRow>
      {(alwaysShowShortContent ||
        values.shortContent.value ||
        getContentLength(values.content.value) > MAX_SHORT_CONTENT_LENGTH) && (
        <FormRow>
          <p>
            <em>{getTwitterText()}</em>
          </p>
          <Label
            text={`Twitter Version (${getContentLength(
              values.shortContent.value
            )} of ${MAX_SHORT_CONTENT_LENGTH})`}
          />
          <TextAreaInput
            name='shortContent'
            value={values.shortContent.value}
            type='input'
            rows={2}
            maxlength={MAX_SHORT_CONTENT_LENGTH}
            error={values.shortContent.error}
            onChange={handleChangeShortContent}
          />
        </FormRow>
      )}
    </>
  )
}

PostContent.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  alwaysShowShortContent: PropTypes.bool,
}

PostContent.defaultProps = {
  alwaysShowShortContent: false,
}

const validatePostContent = async (values, setValues) => {
  try {
    await Yup.object()
      .shape({
        content: Yup.string().min(2).max(MAX_CONTENT_LENGTH).required(),
        shortContent: Yup.string().when('content', (content, schema) =>
          getContentLength(content) > MAX_SHORT_CONTENT_LENGTH
            ? schema.min(2).max(MAX_SHORT_CONTENT_LENGTH)
            : schema
        ),
      })
      .validate(
        {
          content: values.content.value,
          shortContent: values.shortContent.value,
        },
        { abortEarly: false }
      )

    return true
  } catch (error) {
    const errors = {}
    error.inner.map(item => {
      var words = item.message.replace(/([A-Z])/g, ' $1')
      return (errors[item.path] = {
        value: item.value,
        error: words.charAt(0).toUpperCase() + words.slice(1),
      })
    })
    setValues({ ...values, ...errors })

    return false
  }
}

export { validatePostContent }
export default PostContent
