import { API, Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'

const AuthContext = React.createContext()
const WithAuth = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [authenticating, setAuthenticating] = useState(true)
  const [user, setUser] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authUser = await Auth.currentAuthenticatedUser()
        const user = await API.get('users', `/${authUser.username}`)
        setUser(user)
        setAuthenticated(true)
        setAuthenticating(false)
      } catch (e) {
        setAuthenticated(false)
        setAuthenticating(false)
      }
    }

    fetchData()
  }, [])

  const defaultContext = {
    authenticating,
    authenticated,
    setAuthenticated,
    user,
    setUser,
  }
  return <AuthContext.Provider value={defaultContext}>{children}</AuthContext.Provider>
}

export { AuthContext }
export default WithAuth
