import { API } from 'aws-amplify'
import Alert from 'components/alert/Alert'
import Button from 'components/button/Button'
import Loader from 'components/loader/Loader'
import MessageBox from 'components/message-box/MessageBox'
import { AuthContext } from 'contexts/AuthContext'
import { format } from 'date-fns'
import { groupBy } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import ScheduleItem from './schedule-item/ScheduleItem'
import ScheduleItems from './schedule-item/ScheduleItems'
import ScheduleTitle from './schedule-item/ScheduleTitle'
import styles from './Schedule.module.scss'

function Schedule() {
  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [moreLoading, setMoreLoading] = useState(false)
  const [error, setError] = useState('')
  const [pending, setPending] = useState({
    items: [],
    count: null,
  })
  const [scheduled, setScheduled] = useState({
    items: [],
    count: null,
    limit: null,
    last: null,
  })

  const fetchPending = async () => {
    return await API.get('schedule', `/pending`)
  }

  const fetchScheduled = async last => {
    let url = ''
    if (last) {
      url = url + `&last=${last}`
    }
    const data = await API.get('schedule', url)

    const items = groupBy(data.items, function (item) {
      return format(new Date(item.publishAt), 'dd-MM-yyyy')
    })
    data.items = Object.keys(items).map(k => items[k])

    return data
  }

  useEffect(() => {
    const pendingData = async () => {
      setPending(await fetchPending())
    }

    const scheduledData = async () => {
      setScheduled(await fetchScheduled())
    }

    pendingData()
    scheduledData()
  }, [loading])

  const handleDateConfirm = async args => {
    setLoading(true)
    try {
      setError('')
      await API.put('schedule', `/${args[0]}`, {
        body: { publishAt: new Date(args[1]).getTime() },
      })
    } catch (error) {
      setError(error.response.data.message || 'There was an error scheduling your post')
    }
    setLoading(false)
  }

  const handleMore = async () => {
    setMoreLoading(true)
    const data = await fetchScheduled(scheduled.last)
    data.items = scheduled.items.concat(data.items)
    setScheduled(data)
    setMoreLoading(false)
  }

  const handleDelete = async id => {
    await API.del('schedule', `/${id}`)
    setPending(await fetchPending())
    setScheduled(await fetchScheduled())
  }

  return (
    <div>
      <MessageBox
        content={`${
          user.firstNsme ? 'Hi ' + user.firstName : 'Hi'
        }, how's the post search going? Let us know if you have any suggestions!`}
      />

      {scheduled.count === null || pending.count === null || loading ? (
      <div><Loader /></div>
      ) : (
        <>
          {error.length > 0 && (
            <div>
              <Alert type='error'>{error}</Alert>
              <br />
            </div>
          )}

          {pending.items.length > 0 && (
            <>
              <h2 className={styles.header}>Pending Posts</h2>

              <p>
                <i>The posts below require a date and time for publishing.</i>
              </p>

              <div className={styles.posts}>
                {pending.count === null && <Loader />}

                {pending.count === 0 && (
                  <div className={styles.empty}>All done! No posts need scheduling</div>
                )}

                {pending.items.map(item => (
                  <ScheduleItems key={item.id}>
                    <ScheduleItem
                      key={item.id}
                      item={item}
                      handleDateConfirm={handleDateConfirm.bind(this)}
                      handleDelete={handleDelete.bind(this)}
                    />
                  </ScheduleItems>
                ))}
              </div>
            </>
          )}

          <h2 className={styles.header}>Social Calendar</h2>

          <div className={styles.posts}>
            {scheduled.count === null && <Loader />}
            {scheduled.count === 0 && (
              <div className={styles.empty}>
                You don't have anything scheduled at the moment
              </div>
            )}

            {scheduled.items.map(items => (
              <ScheduleItems key={items[0].publishAt}>
                <ScheduleTitle>
                  {format(new Date(items[0].publishAt), 'do LLLL y')}
                </ScheduleTitle>
                {items.map(item => (
                  <ScheduleItem
                    key={item.id}
                    item={item}
                    handleDateConfirm={handleDateConfirm.bind(this)}
                    handleDelete={handleDelete.bind(this)}
                  />
                ))}
              </ScheduleItems>
            ))}
          </div>

          <div className='text-center'>
            {scheduled.last && (
              <Button
                label='Show More'
                loading={moreLoading}
                onClick={handleMore.bind(this)}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Schedule
