import { faClock } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button/Button'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './DateTimePicker.css'
import styles from './DateTimePicker.module.scss'

function DateTimePicker({ date, onClick, onClickArgs, shortDate }) {
  const [componentDate, setComponentDate] = useState(date)
  const [edited, setEdited] = useState(false)

  const handleChange = date => {
    setComponentDate(date)
    setEdited(true)
  }

  return (
    <div className={styles.picker}>
      <DatePicker
        selected={componentDate}
        onChange={handleChange}
        showTimeSelect
        timeFormat='HH:mm'
        timeIntervals={15}
        minDate={new Date()}
        withPortal
        dateFormat='MMMM d, yyyy h:mm aaaa'
        shouldCloseOnSelect={true}
        customInput={
          <div className={styles.pickerLabel}>
            {!componentDate ? (
              'Click to set date to share...'
            ) : (
              <>
                <FontAwesomeIcon icon={faClock} className={styles.icon} />{' '}
                {format(
                  componentDate,
                  shortDate & !edited ? "h:mmaaaaa'm'" : "do LLL h:mmaaaaa'm'"
                )}
              </>
            )}
          </div>
        }
      />

      {edited && componentDate && (
        <Button
        style={{fontSize: '1.4rem', padding: '6px 0'}}
          label='Confirm Date'
          disabled={!componentDate}
          onClick={onClick.bind(this)}
          onClickArgs={onClickArgs.concat([componentDate])}
        />
      )}
    </div>
  )
}

DateTimePicker.propTypes = {
  date: PropTypes.object,
  shortDate: PropTypes.bool,
}

DateTimePicker.defaultProps = {
  date: '',
  shortDate: false,
}

export default DateTimePicker
