import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DropdownItem from 'components/drop-down/DropdownItem'
import DropdownMenu from 'components/drop-down/DropdownMenu'
import { AuthContext } from 'contexts/AuthContext'
import { SearchContext } from 'contexts/SearchContext'
import React, { useContext, useRef } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { NavLink } from 'react-router-dom'
import styles from './Topnav.module.scss'

function Topnav(props) {
  const { user } = useContext(AuthContext)
  const { displaySearch, query, setQuery } = useContext(SearchContext)
  const searchInput = useRef()

  const handleSearch = event => {
    window.scrollTo(0, 0)
    setQuery(event.target.value)

  }

  const handleSearchClick = () => {
    searchInput.current.focus()
  }

  return (
    <div className={styles.header}>
      <div className={styles.inner_header}>
        {displaySearch && (
          <div className={styles.search}>
            <div className={styles.search_icon}>
              <img onClick={handleSearchClick} src='/zoom.svg' alt='magnifying glass' />
            </div>
            <div className={styles.search_bar}>
              <DebounceInput
                inputRef={searchInput}
                placeholder='Discover new social posts'
                minLength={2}
                value={query}
                debounceTimeout={600}
                onChange={handleSearch}
              />
            </div>
          </div>
        )}
        <div className={styles.actions}>
          <div className={styles.dropdownName}>
            {user.firstName} {user.lastName}
          </div>
          <DropdownMenu
            title={<FontAwesomeIcon className={styles.icon} icon={faUserCircle} />}>
            <NavLink to='/logout' exact>
              <DropdownItem>Logout</DropdownItem>
            </NavLink>
          </DropdownMenu>
        </div>
      </div>
    </div>
  )
}

export default Topnav
