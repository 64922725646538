import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import styles from './Alert.module.scss'

function Alert({ type = 'info', closeButton = true, ...props }) {
  const [show, setShow] = useState(true)

  const handleClose = () => {
    setShow(false)
  }

  return (
    show && (
      <div className={`${styles.alert} ${styles[type]}`}>
        {props.children}
        {closeButton && (
          <FontAwesomeIcon
            className={styles.close}
            icon={faTimesCircle}
            onClick={handleClose}
          />
        )}
      </div>
    )
  )
}

export default Alert
