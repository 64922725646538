import React from 'react'
import styles from './MessageBox.module.scss'

function MessageBox(props) {
  return (
    <div className={styles.messageBox}>
      <p>{props.content}</p>
    </div>
  )
}

export default MessageBox
