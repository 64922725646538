import prod from './configs/prod'
import dev from './configs/dev'
import local from './configs/local'

// Default to local if not set
const getConfig = () => {
  switch (process.env.REACT_APP_STAGE) {
    case 'prod':
      return prod
    case 'dev':
      return dev
    default:
      return local
  }
}

export default {
  // Add common config values here
  POST_IMG_MAX_SIZE: 5000000, // 5mb
  POST_IMG_TYPES: ['image/jpeg', 'image/png'], // 5mb
  POST_IMG_WIDTH: 1200,
  POST_IMG_HEIGHT: 628,
  ...getConfig(),
}
