import React from 'react'
import styles from './ScheduleItems.module.scss'

function ScheduleItems(props) {
  return (
    <div className={styles.items}>
      {props.children}
    </div>
  )
}

export default ScheduleItems
