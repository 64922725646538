import algoliasearch from 'algoliasearch/lite'
import MessageBox from 'components/message-box/MessageBox'
import config from 'config'
import { SearchContext } from 'contexts/SearchContext'
import React, { useContext } from 'react'
import { connectHits, InstantSearch, SearchBox } from 'react-instantsearch-dom'
import PostItem from './PostItem'
import styles from './Search.module.scss'

const searchClient = algoliasearch(
  config.algolia.CLIENT_ID,
  config.algolia.SEARCH_ONLY_KEY
)

function PostsSearch(props) {
  const { query } = useContext(SearchContext)

  return (
    <div>
      <MessageBox content={`Search away to find the perfect post!`} />

      <h2 className={styles.header}>Searching All Posts</h2>

      <InstantSearch
        searchClient={searchClient}
        searchState={{ query: query }}
        indexName={config.algolia.POSTS_INDEX}>
        <div className='search-panel'>
          <div>
            <SearchBox className={styles.hidden} />
            <CustomHits />
          </div>
        </div>
      </InstantSearch>
    </div>
  )
}

const Hits = ({ hits }) => (
  <div className={styles.posts}>
    {hits.length === 0 && (
      <div>
        <br />
        <br />
        <i>No posts found</i>
      </div>
    )}
    {hits.map(hit => (
      <PostItem key={hit.id} item={hit} />
    ))}
  </div>
)
const CustomHits = connectHits(Hits)

export default PostsSearch
