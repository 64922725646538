import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from 'contexts/AuthContext'
import NotFound from 'containers/not-found/NotFound'

export default ({ roles = [], component: C, ...routeProps }) => {
  const { authenticating, authenticated, user } = useContext(AuthContext)

  const hasRole = () => {
    if (roles.length > 0 && !roles.includes(user.role)) {
      return false
    }

    return true
  }

  return (
    !authenticating && (
      <Route
        {...routeProps}
        render={props =>
          authenticated ? (
            hasRole() ? (
              <C {...props} />
            ) : (
              <NotFound />
            )
          ) : (
            <Redirect
              to={`/login?redirect=${props.location.pathname}${props.location.search}`}
            />
          )
        }
      />
    )
  )
}
