import React from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import styles from './ListItem.module.scss'

function ListItem({ label, to }) {
  return (
    <NavLink className={styles.item} to={to} exact>
      <div>
        <span className={styles.label}>{label}</span>
        <span><FontAwesomeIcon className={styles.icon} icon={faChevronRight} /></span>
      </div>
    </NavLink>
  )
}

export default ListItem
