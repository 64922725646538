import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import ModalActions from 'components/modal/ModalActions'
import ModalBody from 'components/modal/ModalBody'
import React, { useState } from 'react'
import './DeleteButton.css'
import styles from './DeleteButton.module.scss'

function DeleteButton({
  onDelete,
  deleteId,
  text = 'Are you sure want to delete this?',
}) {
  const [open, setOpen] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const toggleModal = () => {
    setOpen(!open)
  }

  const handleDelete = () => {
    setDeleting(true)
    toggleModal()
    onDelete(deleteId)
  }

  return (
    <div className={styles.container}>
      {deleting ? (
        <img alt='loader' height='18' src='/loader.svg' />
      ) : (
        <FontAwesomeIcon onClick={toggleModal} className={styles.delete} icon={faTrash} />
      )}

      <Modal isOpen={open} onRequestClose={toggleModal}>
        <ModalBody>{text}</ModalBody>
        <ModalActions>
          <Button label='Cancel' onClick={toggleModal} />
          <Button type='bg-red' label='Delete' onClick={handleDelete} />
        </ModalActions>
      </Modal>
    </div>
  )
}

export default DeleteButton
