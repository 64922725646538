import React from 'react'
import styles from './Loader.module.scss'

function Loader() {
  return (
    <div className={styles.loader}>
      <img src='/BesoIcon.svg' alt='Loading' className={styles.pulse} />
    </div>
  )
}

export default Loader
