import { Auth } from 'aws-amplify'
import Button from 'components/button/Button'
import FormRow from 'components/form/form-row/FormRow'
import Label from 'components/form/label/Label'
import TextInput from 'components/form/text-input/TextInput'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Alert from 'components/alert/Alert'
import styles from './ResetPassword.module.scss'

export default function ResetPassword() {
  const [fields, setFields] = useState({
    code: '',
    email: '',
    password: '',
  })
  const [error, setError] = useState('')
  const [codeSent, setCodeSent] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [isSendingCode, setIsSendingCode] = useState(false)

  function validateCodeForm() {
    return fields.email.length > 0
  }

  function validateResetForm() {
    return fields.code.length > 0 && fields.password.length > 0
  }

  const handleFieldChange = event => {
    const { name, value } = event.target
    setFields({ ...fields, [name]: value })
  }

  async function handleSendCodeClick(event) {
    event.preventDefault()

    setIsSendingCode(true)

    try {
      await Auth.forgotPassword(fields.email)
      setCodeSent(true)
    } catch (error) {
      setError(error.message)
      if (error.code === 'UserNotFoundException') {
        setError('User not found')
      } else {
        setError('Error resetting password')
      }
      setIsSendingCode(false)
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault()

    setIsConfirming(true)

    try {
      await Auth.forgotPasswordSubmit(fields.email, fields.code, fields.password)
      setConfirmed(true)
    } catch (error) {
      setError(error.message)
      setIsConfirming(false)
    }
  }

  function renderRequestCodeForm() {
    return (
      <form onSubmit={handleSendCodeClick}>
        <FormRow>
          <Label text='Email' />
          <TextInput name='email' type='email' onChange={handleFieldChange} />
        </FormRow>

        <div className={styles.center}>
          <Button label='Submit' disabled={!validateCodeForm()} loading={isSendingCode} />
        </div>

        <p className={styles.center}>
          <Link to='/login'>Back to login</Link>
        </p>
      </form>
    )
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmClick}>
        <p>Please check your email ({fields.email}) for the confirmation code.</p>
        <br />

        <FormRow>
          <Label text='Confirmation Code' />
          <TextInput
            name='code'
            type='tel'
            value={fields.code}
            onChange={handleFieldChange}
          />
        </FormRow>
        <FormRow>
          <Label text='New Password' />
          <TextInput
            name='password'
            type='password'
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormRow>
        <div className={styles.center}>
          <Button
            label='Confirm'
            disabled={!validateResetForm()}
            loading={isConfirming}
          />
        </div>
      </form>
    )
  }

  function renderSuccessMessage() {
    return (
      <div className='success'>
        <p>Your password has been reset.</p>
        <p>
          <Link to='/login'>Click here to login with your new credentials.</Link>
        </p>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src='/besologo.svg' alt='BeSo Social Logo' />
      </div>
      {error && <Alert type='error'>{error}</Alert>}

      <div className={styles.body}>
        {!codeSent
          ? renderRequestCodeForm()
          : !confirmed
          ? renderConfirmationForm()
          : renderSuccessMessage()}
      </div>
    </div>
  )
}
