export default {
  FACEBOOK_APP_ID: 2778932218852670,
  LINKEDIN_CLIENT_ID: '77uxuilea4sbqt',
  LINKEDIN_REDIRECT_URL: 'https://app.beso.social/linkedin',
  s3: {
    REGION: 'eu-west-2',
    BUCKET: 'be-so-social-infrastructure-posts-pro-mediabucket-1sniqcmo115my',
  },
  apis: {
    posts: {
      REGION: 'eu-west-2',
      URL: 'https://vqskezzfqa.execute-api.eu-west-2.amazonaws.com/prod/v1',
    },
    categories: {
      REGION: 'eu-west-2',
      URL: 'https://arid58lmya.execute-api.eu-west-2.amazonaws.com/prod/v1',
    },
    schedule: {
      REGION: 'eu-west-2',
      URL: 'https://tfup0ouvqk.execute-api.eu-west-2.amazonaws.com/prod/v1',
    },
    tokens: {
      REGION: 'eu-west-2',
      URL: 'https://1qn4f59x01.execute-api.eu-west-2.amazonaws.com/prod/v1/tokens',
    },
    connections: {
      REGION: 'eu-west-2',
      URL: 'https://1qn4f59x01.execute-api.eu-west-2.amazonaws.com/prod/v1/connections',
    },
    socialmedia: {
      REGION: 'eu-west-2',
      URL: 'https://1qn4f59x01.execute-api.eu-west-2.amazonaws.com/prod/v1',
    },
    tips: {
      REGION: 'eu-west-2',
      URL: 'https://x8hi7e28md.execute-api.eu-west-2.amazonaws.com/prod/v1',
    },
    users: {
      REGION: 'eu-west-2',
      URL: 'https://vp5viah406.execute-api.eu-west-2.amazonaws.com/prod/v1/users',
    },
    admins: {
      REGION: 'eu-west-2',
      URL: 'https://vp5viah406.execute-api.eu-west-2.amazonaws.com/prod/v1/admins',
    },
    distributors: {
      REGION: 'eu-west-2',
      URL: 'https://vp5viah406.execute-api.eu-west-2.amazonaws.com/prod/v1/distributors',
    },
  },
  cognito: {
    REGION: 'eu-west-2',
    USER_POOL_ID: 'eu-west-2_AsVPLIDz6',
    APP_CLIENT_ID: '2j6qsvtk12ou5ogbke6o3rjehj',
    IDENTITY_POOL_ID: 'eu-west-2:1bbc6bff-1fab-41db-8a07-f4f7889602f3',
  },
  algolia: {
    CLIENT_ID: '2I2U6GYC07',
    SEARCH_ONLY_KEY: 'eb63a4608ade4ae07d7f2e85a6e85f9d',
    POSTS_INDEX: 'prod_BESO_POSTS',
  },
  imagekit: {
    url: 'https://ik.imagekit.io/kzlwtgtxur/bss',
  },
}
