import React from 'react'
import styles from './DropDownSelect.module.scss'

function DropDownSelect(props) {
  return (
    <div>
      <select
        className={styles.input}
        name={props.name}
        onChange={props.onChange}
        value={props.value || ''}>
        {props.options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {props.error && <div className={styles.error}>{props.error}</div>}
    </div>
  )
}

export default DropDownSelect
