import { API } from 'aws-amplify'
import BlockArea from 'components/block-area/BlockArea'
import BlockItem from 'components/block-area/BlockItem'
import Button from 'components/button/Button'
import ListArea from 'components/list-area/ListArea'
import ListItem from 'components/list-area/ListItem'
import MessageBox from 'components/message-box/MessageBox'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

function Categories(props) {
  const { user } = useContext(AuthContext)
  const [categories, setCategories] = useState({
    items: [],
    count: null,
  })
  const [popularCategories, setPopularCategories] = useState({
    items: [],
    count: null,
  })
  const { addToast } = useToasts()

  const fetchCategories = async (params = {}) => {
    return await API.get('categories', '')
  }

  const fetchPopularCategories = async (params = {}) => {
    return await API.get('categories', '?popular=1')
  }

  useEffect(() => {
    const fetchData = async () => {
      const all = await fetchCategories()
      setCategories(all)

      const popular = await fetchPopularCategories()
      setPopularCategories(popular)
    }

    fetchData()

    if (props.location.state) {
      addToast(props.location.state.message, {
        appearance: props.location.state.type,
        autoDismiss: true,
      })
    }
  }, [addToast, props.location.state])

  const headerType = index => {
    const mod = index % 4

    if (mod % 4 === 0) {
      return 'bg-blue'
    } else if (mod % 3 === 0) {
      return 'bg-green'
    } else if (mod % 2 === 0) {
      return 'bg-orange'
    }

    return 'bg-purple'
  }

  return (
    <div>
      <MessageBox
        content={`${
          user.firstNsme ? 'Hi ' + user.firstName : 'Hi'
        }, did you know that you can edit posts before they go out to give them that personal touch?`}
      />

      <BlockArea
        title='Most Popular Categories'
        loading={popularCategories.count === null}>
        {popularCategories.items.map((item, index) => (
          <BlockItem
            key={item.id}
            label={item.name}
            type={headerType(index + 1)}
            style={{
              backgroundColor: `#${item.color}20`,
              borderColor: `#${item.color}`,
              color: `#${item.color}`,
            }}
            to={`/social-posts/category/${item.id}/${item.name}`}>
            {item.description}
          </BlockItem>
        ))}
      </BlockArea>

      <ListArea title='Categories A-Z' loading={categories.count === null}>
        {categories.items.map(item => (
          <ListItem
            key={item.id}
            label={item.name}
            to={`/social-posts/category/${item.id}/${item.name.replace('/', '::')}`}
          />
        ))}
      </ListArea>

      {user.role === 'admin' && (
        <Link to='/categories/create' exact>
          <Button label='Create Category' />
        </Link>
      )}
    </div>
  )
}

export default Categories
