import config from 'config'
import React, { useState } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/lib/ReactCrop.scss'

const getCroppedImg = (image, crop) => {
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height

  canvas.width = crop.width * scaleX
  canvas.height = crop.height * scaleY
  const ctx = canvas.getContext('2d')

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  )

  return canvas.toDataURL('image/jpeg')
}

export default function ({ imageBase64, onChange, style = {} }) {
  const [crop, setCrop] = useState()
  const [image, setImage] = useState()
  const [scaleX, setScaleX] = useState(1)
  const [scaleY, setScaleY] = useState(1)

  const handleImageLoaded = image => {
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    setScaleX(scaleX)
    setScaleY(scaleY)

    const newCrop = {
      x: 0,
      y: 0,
      width: config.POST_IMG_WIDTH / scaleX,
      height: config.POST_IMG_HEIGHT / scaleY,
    }

    setCrop(newCrop)
    setImage(image)

    // handle submitting if no crop adjustments made
    const img = getCroppedImg(image, newCrop)
    onChange(img)
  }

  const handleCropComplete = crop => {
    if (image && crop.width && crop.height) {
      const img = getCroppedImg(image, crop)
      onChange(img)
    }
  }

  return (
    <ReactCrop
      style={style}
      src={imageBase64}
      keepSelection={true}
      minWidth={config.POST_IMG_WIDTH / scaleX}
      minHeight={config.POST_IMG_HEIGHT / scaleY}
      crop={crop}
      onImageLoaded={image => {
        handleImageLoaded(image)
        return false
      }}
      onChange={newCrop => setCrop(newCrop)}
      onComplete={newCrop => handleCropComplete(newCrop)}
    />
  )
}
