import React from 'react'
import styles from './Badge.module.scss'

function Badge(props) {
  return (
    <div className={`${styles.badge} ${props.className}`}>
      {props.children}
    </div>
  )
}

export default Badge
