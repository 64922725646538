import Button from 'components/button/Button'
import React, { useState } from 'react'
import Modal from 'components/modal/Modal'
import styles from './InfoModal.module.scss'

function InfoModal({ icon, text }) {
  const [open, setOpen] = useState(false)

  const toggleModal = () => {
    setOpen(!open)
  }

  return (
    <div className={styles.container}>
      <div onClick={toggleModal} className={styles.info}>
        {icon}
      </div>

      <Modal isOpen={open} onRequestClose={toggleModal}>
        <div className={styles.text}>{text}</div>
        <div className={styles.actions}>
          <Button label='Cancel' onClick={toggleModal} />
        </div>
      </Modal>
    </div>
  )
}

export default InfoModal
