import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import {
  faBars,
  faBullhorn,
  faCalendarAlt,
  faChartLine,
  faChevronDown,
  faChevronRight,
  faCog,
  faLightbulb,
  faList,
  faUserCog,
  faUserLock,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styles from './Sidenav.module.scss'

function Sidenav() {
  const { user } = useContext(AuthContext)
  const [expanded, setExpanded] = useState(false)
  const [settings, setSettings] = useState(false)
  const [analytics, setAnalytics] = useState(false)
  const [users, setUsers] = useState(false)
  const handleToggle = () => setExpanded(!expanded)
  const handleClick = () => setExpanded(false)
  const handleSettings = () => setSettings(!settings)
  const handleAnalytics = () => setAnalytics(!analytics)
  const handleUsers = () => setUsers(!users)

  const isDistributor = () => {
    return user.role === 'distributor' && user.limit > 0
  }

  const isAdmin = () => {
    return user.role === 'admin'
  }

  return (
    <div className={styles.sidenav}>
      <div className={styles.topPanel}>
        <div className={styles.toggle} onClick={handleToggle}>
          <FontAwesomeIcon className={styles.menuIcon} icon={faBars} />
        </div>
        <div className={styles.logo}>
          <NavLink to='/' exact onClick={handleClick}>
            <img height='50' src='/besologo.svg' alt='BeSo Social Logo' />
          </NavLink>
        </div>
      </div>

      <div className={styles.panel}>
        <div className={`${styles.items} ${expanded ? styles.expanded : ''}`}>
          <NavLink
            className={styles.link + ' green-menu'}
            to='/social-posts'
            exact
            activeClassName={styles.active}
            onClick={handleClick}>
            <FontAwesomeIcon className={styles.icon} icon={faBullhorn} />
            Social Posts
            <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
          </NavLink>
          {isDistributor() && (
            <NavLink
              className={styles.link}
              to='/custom-post/create'
              exact
              activeClassName={styles.active}
              onClick={handleClick}>
              <FontAwesomeIcon className={styles.icon} icon={faBullhorn} />
              Custom Post
              <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
            </NavLink>
          )}
          <NavLink
            className={styles.link + ' yellow-menu'}
            to='/categories'
            exact
            activeClassName={styles.active}
            onClick={handleClick}>
            <FontAwesomeIcon className={styles.icon} icon={faList} />
            Categories
            <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
          </NavLink>
          {!isAdmin() && (
            <NavLink
              className={styles.link + ' blue-menu'}
              to='/schedule'
              exact
              activeClassName={styles.active}
              onClick={handleClick}>
              <FontAwesomeIcon className={styles.icon} icon={faCalendarAlt} />
              Schedule
              <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
            </NavLink>
          )}
          {!isAdmin() && (
            <>
              <div className={styles.link} onClick={handleAnalytics}>
                <FontAwesomeIcon className={styles.icon} icon={faChartLine} />
                Analytics
                <FontAwesomeIcon
                  className={styles.rightIcon + ' orange-menu'}
                  icon={analytics ? faChevronDown : faChevronRight}
                />
              </div>
              <div className={`${styles.subItems} ${analytics ? styles.expanded : ''}`}>
                <NavLink
                  className={styles.link}
                  to='/facebook/analytics'
                  exact
                  activeClassName={styles.active}
                  onClick={handleClick}>
                  <FontAwesomeIcon className={styles.icon} icon={faFacebookF} />
                  Facebook
                  <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
                </NavLink>
              </div>
              <div className={`${styles.subItems} ${analytics ? styles.expanded : ''}`}>
                <NavLink
                  className={styles.link}
                  to='/linkedin/analytics'
                  exact
                  activeClassName={styles.active}
                  onClick={handleClick}>
                  <FontAwesomeIcon className={styles.icon} icon={faLinkedinIn} />
                  LinkedIn
                  <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
                </NavLink>
              </div>
            </>
          )}
          <NavLink
            className={styles.link}
            to='/social-tips'
            exact
            activeClassName={styles.active}
            onClick={handleClick}>
            <FontAwesomeIcon className={styles.icon} icon={faLightbulb} />
            Social Tips
            <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
          </NavLink>
          {isDistributor() && (
            <NavLink
              className={styles.link}
              to='/users'
              exact
              activeClassName={styles.active}
              onClick={handleClick}>
              <FontAwesomeIcon className={styles.icon} icon={faUsers} />
              Users
              <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
            </NavLink>
          )}
          {isAdmin() && (
            <>
              <div className={styles.link} onClick={handleUsers}>
                <FontAwesomeIcon className={styles.icon} icon={faUsers} />
                Users
                <FontAwesomeIcon
                  className={styles.rightIcon}
                  icon={users ? faChevronDown : faChevronRight}
                />
              </div>
              <div className={`${styles.subItems} ${users ? styles.expanded : ''}`}>
                <NavLink
                  className={styles.link}
                  to='/admins'
                  exact
                  activeClassName={styles.active}
                  onClick={handleClick}>
                  <FontAwesomeIcon className={styles.icon} icon={faUserLock} />
                  Admins
                  <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
                </NavLink>
                <NavLink
                  className={styles.link}
                  to='/distributors'
                  exact
                  activeClassName={styles.active}
                  onClick={handleClick}>
                  <FontAwesomeIcon className={styles.icon} icon={faUserCog} />
                  Distributors
                  <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
                </NavLink>
              </div>
            </>
          )}
          {!isAdmin() && (
            <>
              <div className={styles.link} onClick={handleSettings}>
                <FontAwesomeIcon className={styles.icon} icon={faCog} />
                Settings
                <FontAwesomeIcon
                  className={styles.rightIcon}
                  icon={settings ? faChevronDown : faChevronRight}
                />
              </div>
              <div className={`${styles.subItems} ${settings ? styles.expanded : ''}`}>
                <NavLink
                  className={styles.link}
                  to='/social-media-connect'
                  exact
                  activeClassName={styles.active}
                  onClick={handleClick}>
                  <FontAwesomeIcon className={styles.icon} icon={faCog} />
                  Social Media
                  <FontAwesomeIcon className={styles.rightIcon} icon={faChevronRight} />
                </NavLink>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Sidenav
