import React from 'react'
import styles from './TextAreaInput.module.scss'

function TextAreaInput(props) {
  return (
    <div>
      <textarea
        className={styles.input}
        name={props.name}
        rows={props.rows || 10}
        onChange={props.onChange}
        value={props.value || ''}
        maxLength={props.maxlength || '4000'}
      />

      {props.error && <div className={styles.error}>{props.error}</div>}
    </div>
  )
}

export default TextAreaInput
