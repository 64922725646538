import { Auth } from 'aws-amplify'
import Alert from 'components/alert/Alert'
import Button from 'components/button/Button'
import FormRow from 'components/form/form-row/FormRow'
import Label from 'components/form/label/Label'
import TextInput from 'components/form/text-input/TextInput'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import styles from './Welcome.module.scss'

export default function Welcome() {
  const { identifier } = useParams()
  const [email, setEmail] = useState('')
  const [fields, setFields] = useState({
    code: '',
    password: '',
  })
  const [error, setError] = useState('')
  const [codeSent, setCodeSent] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [isSendingCode, setIsSendingCode] = useState(false)

  useEffect(() => {
    try {
      setEmail(atob(decodeURIComponent(identifier)))
    } catch (error) {
      setEmail('')
    }
  }, [identifier])

  function validateResetForm() {
    return fields.code.length > 0 && fields.password.length > 0
  }

  const handleFieldChange = event => {
    const { name, value } = event.target
    setFields({ ...fields, [name]: value })
  }

  async function handleSendCodeClick(event) {
    event.preventDefault()

    setIsSendingCode(true)

    try {
      await Auth.forgotPassword(email)
      setCodeSent(true)
    } catch (error) {
      setError('There was an issue getting your account setup')
      setIsSendingCode(false)
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault()

    setIsConfirming(true)

    try {
      await Auth.forgotPasswordSubmit(email, fields.code, fields.password)
      setConfirmed(true)
    } catch (error) {
      setError(error.message)
      setIsConfirming(false)
    }
  }

  function renderRequestCodeForm() {
    return (
      <>
        <p className='text-center'>Welcome to BeSo Social!</p>
        <p className='text-center'>
          To get started you will need to verify your email ({email}) and set a password.
        </p>
        <p className='text-center'>
          Click the "Send Confirmation Code" button below to get the process underway.
        </p>
        <form onSubmit={handleSendCodeClick} className={styles.start}>
          <div className={styles.center}>
            <Button label='Send Confirmation Code' loading={isSendingCode} />
          </div>
        </form>
      </>
    )
  }

  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmClick}>
        <p>Please check your email ({email}) for the confirmation code.</p>
        <br />

        <FormRow>
          <Label text='Confirmation Code' />
          <TextInput
            name='code'
            type='tel'
            value={fields.code}
            onChange={handleFieldChange}
          />
        </FormRow>
        <FormRow>
          <Label text='Password' />
          <TextInput
            name='password'
            type='password'
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormRow>
        <div className={styles.center}>
          <Button
            label='Confirm'
            disabled={!validateResetForm()}
            loading={isConfirming}
          />
        </div>
      </form>
    )
  }

  function renderSuccessMessage() {
    return (
      <div className='success'>
        <p className='text-center'>
          Your account has been setup and your password set. You are good to go!
        </p>
        <p className='text-center'>
          <Link to='/login'>Click here to login.</Link>
        </p>
      </div>
    )
  }

  if (!email) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src='/besologo.svg' alt='BeSo Social Logo' />
      </div>
      {error && <Alert type='error'>{error}</Alert>}

      <div className={styles.body}>
        {!codeSent
          ? renderRequestCodeForm()
          : !confirmed
          ? renderConfirmationForm()
          : renderSuccessMessage()}
      </div>
    </div>
  )
}
