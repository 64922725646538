import { ResponsiveLine } from '@nivo/line'
import { format } from 'date-fns'
import React from 'react'
import styles from './LineChart.module.scss'

function LineChart({
  data,
  tooltipX = 'x',
  tooltipY = 'y',
  colors = { scheme: 'nivo' },
}) {
  const toolTipElement = props => {
    return (
      <div className={styles.tooltip}>
        {tooltipX}: {props.point.data.xFormatted}
        <br />
        {tooltipY}: {props.point.data.yFormatted}
      </div>
    )
  }

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 15, right: 20, bottom: 50, left: 30 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false,
      }}
      curve='linear'
      enableArea='true'
      tooltip={toolTipElement}
      enableGridX={false}
      enableGridY={false}
      axisTop={null}
      axisRight={null}
      xFormat={v => format(new Date(v), 'dd/MM/yyyy')}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        format: v => format(new Date(v), 'dd'),
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendOffset: 36,
        legendPosition: 'middle',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      lineWidth={'6px'}
      colors={colors}
      enablePoints={false}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointLabel='y'
      pointLabelYOffset={-12}
      useMesh={true}
    />
  )
}

export default LineChart
