import { Storage } from 'aws-amplify'
import Loader from 'components/loader/Loader'
import config from 'config'
import React, { useEffect, useState } from 'react'

const getPath = path => {
  return path.replace(/ /g, '_')
}

function S3Image({ path, height, width, className }) {
  const [imgUrl, setImgUrl] = useState('')

  useEffect(() => {
    const fetchImgUrl = async () => {
      config.imagekit.url
        ? setImgUrl(`${config.imagekit.url}/${getPath(path)}?tr=h-${height},w-${width},cm-pad_resize`)
        : setImgUrl(await Storage.get(getPath(path)))
    }

    fetchImgUrl()
  })

  return imgUrl ? <img alt='post' height={`${height}px`} src={`${imgUrl}`} className={className ? className : ''} /> : <Loader />
}

export default S3Image
