import { API } from 'aws-amplify'
import BlockArea from 'components/block-area/BlockArea'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import BlockItem from 'components/block-area/BlockItem'
import LineChart from 'components/charts/LineChart'
import Loader from 'components/loader/Loader'
import MessageBox from 'components/message-box/MessageBox'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useEffect, useState } from 'react'
import styles from './Overview.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faComment, faComments } from '@fortawesome/free-regular-svg-icons'
import {
  faRing,
  faUsers,
  faUser,
  faShare,
  faQuoteRight,
} from '@fortawesome/free-solid-svg-icons'

function Overview() {
  const { user } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [analytics, setAnalytics] = useState([])

  const fetchAnalytics = async () => {
    return await API.get('socialmedia', '/linkedin/analytics/overview')
  }

  useEffect(() => {
    const fetchData = async () => {
      const analytics = await fetchAnalytics()
      setAnalytics(analytics)
      setLoading(false)
    }

    fetchData()
  }, [])

  return (
    <div>
      <MessageBox
        content={`${
          user.firstNsme ? 'Hi ' + user.firstName : 'Hi'
        }, see some insights into your LinkedIn data below`}
      />

      <h1>
        <FontAwesomeIcon className={styles.headerIcon} icon={faLinkedin} /> Organisation
        Analytics
      </h1>

      {loading && <Loader />}

      {!loading && analytics.length === 0 && (
        <div>
          <br />
          <i>You'll need to a LinkedIn profile and organisations to see analytics</i>
        </div>
      )}

      {analytics.map(org => (
        <div className={styles.area}>
          <br/><h2>{org.orgName}</h2><br/>
          <h3>Lifetime Stats</h3>
          <BlockArea cols='4'>
            <BlockItem key='lifetime-like-count' label='Likes' type='bg-purple'>
              <div className={styles.fans}>
                {org.data.lifetime.likeCount}
                <FontAwesomeIcon className={styles.icon} icon={faThumbsUp} />
              </div>
            </BlockItem>

            <BlockItem key='lifetime-engagement' label='Engagement' type='bg-blue'>
              <div className={styles.fans}>
                {org.data.lifetime.engagement.toFixed(2)}
                <FontAwesomeIcon className={styles.icon} icon={faRing} />
              </div>
            </BlockItem>

            <BlockItem
              key='lifetime-unique-impressions-count'
              label='Unique Impressions'
              type='bg-green'>
              <div className={styles.fans}>
                {org.data.lifetime.uniqueImpressionsCount}
                <FontAwesomeIcon className={styles.icon} icon={faUser} />
              </div>
            </BlockItem>

            <BlockItem
              key='lifetime-impression-count'
              label='Impressions'
              type='bg-green'>
              <div className={styles.fans}>
                {org.data.lifetime.impressionCount}
                <FontAwesomeIcon className={styles.icon} icon={faUsers} />
              </div>
            </BlockItem>

            <BlockItem key='lifetime-share-count' label='Shares' type='bg-orange'>
              <div className={styles.fans}>
                {org.data.lifetime.shareCount}
                <FontAwesomeIcon className={styles.icon} icon={faShare} />
              </div>
            </BlockItem>

            <BlockItem
              key='lifetime-share-mention-count'
              label='Share Mentions'
              type='bg-orange'>
              <div className={styles.fans}>
                {org.data.lifetime.shareMentionsCount}
                <FontAwesomeIcon className={styles.icon} icon={faQuoteRight} />
              </div>
            </BlockItem>

            <BlockItem key='lifetime-comment-count' label='Comments' type='bg-yellow'>
              <div className={styles.fans}>
                {org.data.lifetime.commentCount}
                <FontAwesomeIcon className={styles.icon} icon={faComment} />
              </div>
            </BlockItem>

            <BlockItem
              key='lifetime-comment-mentions-count'
              label='Comment Mentions'
              type='bg-yellow'>
              <div className={styles.fans}>
                {org.data.lifetime.commentMentionsCount}
                <FontAwesomeIcon className={styles.icon} icon={faComments} />
              </div>
            </BlockItem>
          </BlockArea>

          <h3>28 Day Breakdown</h3>
          <BlockArea cols='2'>
            <BlockItem key='breakdown-like-count' label='Likes' type='bg-purple'>
              <div className={styles.chart}>
                <LineChart
                  data={[
                    {
                      id: 'Likes',
                      data: org.data.breakdown.likeCount.values.map(item => {
                        return {
                          x: item.end_time,
                          y: item.value,
                        }
                      }),
                    },
                  ]}
                  colors={['#9b27f5']}
                  tooltipX='Date'
                  tooltipY='Likes'
                />
              </div>
            </BlockItem>

            <BlockItem key='breakdown-engagement' label='Engagement' type='bg-blue'>
              <div className={styles.chart}>
                <LineChart
                  data={[
                    {
                      id: 'Engagement',
                      data: org.data.breakdown.engagement.values.map(item => {
                        return {
                          x: item.end_time,
                          y: item.value.toFixed(2),
                        }
                      }),
                    },
                  ]}
                  colors={['#2da0f9']}
                  tooltipX='Date'
                  tooltipY='Engagement'
                />
              </div>
            </BlockItem>

            <BlockItem
              key='breakdown-impressions-count'
              label='Impressions'
              type='bg-green'>
              <div className={styles.chart}>
                <LineChart
                  data={[
                    {
                      id: 'Impressions',
                      data: org.data.breakdown.impressionCount.values.map(item => {
                        return {
                          x: item.end_time,
                          y: item.value,
                        }
                      }),
                    },
                  ]}
                  colors={['#50d168']}
                  tooltipX='Date'
                  tooltipY='Impressions'
                />
              </div>
            </BlockItem>

            <BlockItem key='breakdown-share-count' label='Shares' type='bg-orange'>
              <div className={styles.chart}>
                <LineChart
                  data={[
                    {
                      id: 'Shares',
                      data: org.data.breakdown.shareCount.values.map(item => {
                        return {
                          x: item.end_time,
                          y: item.value,
                        }
                      }),
                    },
                  ]}
                  colors={['#ff9800']}
                  tooltipX='Date'
                  tooltipY='Shares'
                />
              </div>
            </BlockItem>

            <BlockItem key='breakdown-comment-count' label='Comments' type='bg-yellow'>
              <div className={styles.chart}>
                <LineChart
                  data={[
                    {
                      id: 'Comments',
                      data: org.data.breakdown.commentCount.values.map(item => {
                        return {
                          x: item.end_time,
                          y: item.value,
                        }
                      }),
                    },
                  ]}
                  colors={['#f5d327']}
                  tooltipX='Date'
                  tooltipY='Comments'
                />
              </div>
            </BlockItem>

            <BlockItem key='breakdown-click-count' label='Clicks' type='bg-purple'>
              <div className={styles.chart}>
                <LineChart
                  data={[
                    {
                      id: 'Clicks',
                      data: org.data.breakdown.clickCount.values.map(item => {
                        return {
                          x: item.end_time,
                          y: item.value,
                        }
                      }),
                    },
                  ]}
                  colors={['#9b27f5']}
                  tooltipX='Date'
                  tooltipY='Clicks'
                />
              </div>
            </BlockItem>
          </BlockArea>
        </div>
      ))}
    </div>
  )
}

export default Overview
