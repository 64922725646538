import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from 'aws-amplify'
import Alert from 'components/alert/Alert'
import Button from 'components/button/Button'
import Loader from 'components/loader/Loader'
import TokenExpired from 'components/token-expired/TokenExpired'
import config from 'config'
import React, { useEffect, useState } from 'react'
import { LinkedIn } from 'react-linkedin-login-oauth2'
import { NavLink } from 'react-router-dom'
import styles from './Login.module.scss'

function LinkedInLogin(props) {
  const [connected, setConnected] = useState(false)
  const [loading, setLoading] = useState(true)
  const [expired, setExpired] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const res = await API.get('tokens', '/linkedin')
        setConnected(true)
        setExpired(res.expired)
        setConnected(true)
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }

    fetchToken()
  }, [])

  const handleFailure = error => {
    setError('An error has occurred')
  }

  const handleSuccess = async response => {
    await API.put('tokens', '/linkedin', {
      body: {
        clientId: config.LINKEDIN_CLIENT_ID,
        redirectUri: config.LINKEDIN_REDIRECT_URL,
        code: response.code,
      },
    })
    setConnected(true)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      {error && <Alert type='error'>{error}</Alert>}

      <FontAwesomeIcon className={styles.icon} icon={faLinkedin} />

      <br />

      {expired && <TokenExpired />}

      <div>
        <LinkedIn
          clientId={config.LINKEDIN_CLIENT_ID}
          scope='r_basicprofile,r_emailaddress,w_member_social,rw_organization_admin,w_organization_social'
          onFailure={handleFailure.bind(this)}
          onSuccess={handleSuccess.bind(this)}
          redirectUri={config.LINKEDIN_REDIRECT_URL}
          renderElement={({ onClick, disabled }) => (
            <div className={styles.buttons}>
              <Button
                label={connected ? 'Refresh Connection' : 'Connect Profile'}
                onClick={onClick}
                loading={disabled}
              />

              {connected && (
                <NavLink to='/social-media-connect/linkedin' exact>
                  <Button label='Manage Organisations' />
                </NavLink>
              )}
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default LinkedInLogin
