import { API } from 'aws-amplify'
import Loader from 'components/loader/Loader'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

function TwitterAuthorise(props) {
  const [loading, setLoading] = useState(true)
  const [connected, setConnected] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const getOauthToken = async (oauthVerifier, oauthToken) => {
      try {
        await API.post('tokens', '/twitter/accessToken', {
          body: {
            verifier: oauthVerifier,
            token: oauthToken,
          },
        })

        setConnected(true)
        window.close()
      } catch (error) {
        setConnected(false)
      }

      setLoading(false)
    }

    const query = new URLSearchParams(location.search)
    const oauthToken = query.get('oauth_token')
    const oauthVerifier = query.get('oauth_verifier')

    if (!oauthToken || !oauthVerifier) {
      props.history.push({
        pathname: '/not-found',
      })
    }

    getOauthToken(oauthVerifier, oauthToken)
  }, [location.search, props.history])

  if (loading) {
    return <Loader />
  }

  return (
    <div class='text-center'>
      <br/>
      {connected ? 'Successfully connected to Twitter' : 'There was an error connected to Twitter'}
    </div>
  )
}

export default TwitterAuthorise
