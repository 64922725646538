import { API, Auth } from 'aws-amplify'
import Alert from 'components/alert/Alert'
import Button from 'components/button/Button'
import FormRow from 'components/form/form-row/FormRow'
import Label from 'components/form/label/Label'
import TextInput from 'components/form/text-input/TextInput'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './Login.module.scss'

function Login() {
  const { setAuthenticated, setUser } = useContext(AuthContext)
  const [newPasswordRequired, setNewPasswordRequired] = useState(false)
  const [cognitoAuthUser, setCognitoAuthUser] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: value })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (validateForm()) {
      setLoading(true)

      try {
        const authUser = await Auth.signIn(values.email, values.password)
        if (authUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setCognitoAuthUser(authUser)
          setNewPasswordRequired(true)
          setLoading(false)
        } else {
          const user = await API.get('users', `/${authUser.username}`)
          setUser(user)
          setAuthenticated(true)
          setLoading(false)
        }
      } catch (error) {
        if (error.code === 'NotAuthorizedException') {
          setError('Invalid username or password')
        } else if (error.code === 'UserNotFoundException') {
          setError('Invalid username or password')
        } else {
          setError("We're having trouble logging you in at the moment")
        }
        setLoading(false)
      }
    } else {
      setLoading(false)
      setError('Invalid email or password')
    }
  }

  const handleSubmitNewPassword = async event => {
    event.preventDefault()

    if (validateNewPasswordForm()) {
      setLoading(true)

      try {
        await Auth.completeNewPassword(cognitoAuthUser, values.new_password)
        const user = await API.get('users', `/${cognitoAuthUser.username}`)
        setUser(user)
        setAuthenticated(true)
        setLoading(false)
      } catch (error) {
        setError(error.message)
        setLoading(false)
      }
    } else {
      setLoading(false)
      setError('Invalid password')
    }
  }

  const validateForm = () => {
    return values.email.length > 0 && values.password.length > 0
  }

  const validateNewPasswordForm = () => {
    return values.new_password.length > 0
  }

  const [values, setValues] = useState({
    email: '',
    password: '',
    new_password: '',
  })

  function renderLoginForm() {
    return (
      <form onSubmit={handleSubmit}>
        <div className={styles.ma}>
        <FormRow>
          <Label text='Email' />
          <TextInput name='email' type='email' onChange={handleChange.bind(this)} />
        </FormRow>

        <FormRow>
          <Label text='Password' />
          <TextInput
            name='password'
            type='password'
            value={values.password}
            onChange={handleChange.bind(this)}
          />
        </FormRow>

        <div className={styles.center}>
          <Button label='Login' loading={loading} />
        </div>

        <p className={styles.center}>
          <Link to='/reset-password'>Forgot password</Link>
        </p>
        </div>
      </form>
    )
  }

  function renderNewPasswordRequired() {
    return (
      <form onSubmit={handleSubmitNewPassword}>
        <p>Please set a new password</p>
        <br />

        <FormRow>
          <Label text='Password' />
          <TextInput
            name='new_password'
            type='password'
            value={values.new_password}
            onChange={handleChange.bind(this)}
          />
        </FormRow>

        <div className={styles.center}>
          <Button label='Confirm Password' loading={loading} />
        </div>
      </form>
    )
  }

  return (
    <div className={styles.login}>
      <div className={styles.header}>
        <img src='/besologo.svg' alt='BeSo Social Logo' />
      </div>
      {error && <Alert type='error'>{error}</Alert>}

      <div className={styles.body}>
        {newPasswordRequired ? renderNewPasswordRequired() : renderLoginForm()}
      </div>
    </div>
  )
}

export default Login
