import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from 'aws-amplify'
import Alert from 'components/alert/Alert'
import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import ModalActions from 'components/modal/ModalActions'
import ModalBody from 'components/modal/ModalBody'
import Switch from 'components/Switch'
import Tooltip from 'components/Tooltip'
import React, { useState } from 'react'
import styles from './PostTo.module.scss'

function PostTo({ item }) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [itemPublishTo, setItemPublishTo] = useState(item.publishTo)
  const [publishTo, setPublishTo] = useState(item.publishTo)

  const handleChangePublishTo = value => {
    const index = publishTo.indexOf(value)
    if (index === -1) {
      const newPublishTo = Array.from(publishTo)
      newPublishTo.push(value)
      setPublishTo(newPublishTo)
    } else {
      const newPublishTo = Array.from(publishTo)
      newPublishTo.splice(index, 1)
      setPublishTo(newPublishTo)
    }
  }

  const handleChangeLinkedInProfile = () => {
    handleChangePublishTo('LINKEDIN_USER')
  }

  const handleChangeLinkedInOrgs = () => {
    handleChangePublishTo('LINKEDIN_ORGS')
  }

  const handleChangeFacebook = () => {
    handleChangePublishTo('FACEBOOK')
  }

  const handleChangeTwitter = () => {
    handleChangePublishTo('TWITTER')
  }

  const toggleModal = () => {
    setOpen(!open)
  }

  const handleSubmit = async () => {
    setError('')

    if (publishTo.length === 0) {
      setError('You you select at least one')
      return
    }

    setLoading(true)

    try {
      await API.put('schedule', `/${item.id}`, {
        body: {
          publishTo: publishTo,
        },
      })

      setLoading(false)
      setItemPublishTo(publishTo)
    } catch (e) {
      setError('There was error updating')
    }

    setOpen(false)
  }

  return (
    <>
      <div className={styles.postTo} onClick={toggleModal}>
        Publishing to...{' '}
        {itemPublishTo.includes('LINKEDIN_USER') && (
          <FontAwesomeIcon
            className={styles.linkedin}
            icon={faLinkedin}
            data-tip='LinkedIn Personal Profile'
          />
        )}
        {itemPublishTo.includes('LINKEDIN_ORGS') && (
          <FontAwesomeIcon
            className={styles.linkedin}
            icon={faLinkedin}
            data-tip='LinkedIn Orgs'
          />
        )}
        {itemPublishTo.includes('FACEBOOK') && (
          <FontAwesomeIcon
            className={styles.facebook}
            icon={faFacebook}
            data-tip='Facebook'
          />
        )}
        {itemPublishTo.includes('TWITTER') && (
          <FontAwesomeIcon
            className={styles.twitter}
            icon={faTwitter}
            data-tip='Twitter'
          />
        )}
        <Tooltip />
      </div>

      <Modal isOpen={open} onRequestClose={toggleModal}>
        <ModalBody>
          <p>
            <strong>Where would you like to publish this post?</strong>
            <small className={styles.smallprint}>
              This assumes you have connected the social media account within the Settings
              section.
            </small>
          </p>

          {error && (
            <p>
              <Alert type='error'>{error}</Alert>
            </p>
          )}

          <form className={styles.form}>
            <label>
              <span>
                <FontAwesomeIcon className={styles.linkedin} icon={faLinkedin} /> LinkedIn
                Personal Profile
              </span>
              <Switch
                onChange={handleChangeLinkedInProfile}
                checked={publishTo.includes('LINKEDIN_USER')}
              />
            </label>

            <label>
              <span>
                <FontAwesomeIcon className={styles.linkedin} icon={faLinkedin} /> LinkedIn
                Organisations
              </span>
              <Switch
                onChange={handleChangeLinkedInOrgs}
                checked={publishTo.includes('LINKEDIN_ORGS')}
              />
            </label>

            <label>
              <span>
                <FontAwesomeIcon className={styles.facebook} icon={faFacebook} /> Facebook
                Pages
              </span>
              <Switch
                onChange={handleChangeFacebook}
                checked={publishTo.includes('FACEBOOK')}
              />
            </label>

            <label>
              <span>
                <FontAwesomeIcon className={styles.twitter} icon={faTwitter} /> Twitter
              </span>
              <Switch
                onChange={handleChangeTwitter}
                checked={publishTo.includes('TWITTER')}
              />
            </label>
          </form>
        </ModalBody>
        <ModalActions>
          <Button disabled={loading} label='Cancel' onClick={toggleModal} />
          <Button loading={loading} type='bg-green' label='Save' onClick={handleSubmit} />
        </ModalActions>
      </Modal>
    </>
  )
}

export default PostTo
