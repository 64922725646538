import Alert from 'components/alert/Alert'
import React from 'react'

function TokenExpired() {
  return (
    <>
      <Alert type='error' closeButton={false}>
        This token has expired, please refresh this connection.
      </Alert>
      <br />
    </>
  )
}

export default TokenExpired
