import React from 'react'
import Loader from 'components/loader/Loader'
import styles from './Table.module.scss'

function Table({ title, loading, ...props }) {
  return (
    <div>
      <h2 className={styles.header}>{title}</h2>
      <div className={`${styles.container} shadow`}>
        {loading && <Loader />}
        {!loading && (
          <div className={styles.tableContainer}>
            <table className={styles.table}>{props.children}</table>
          </div>
        )}
      </div>
    </div>
  )
}

export default Table
