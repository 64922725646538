import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DateTimePicker from 'components/date-time-picker/DateTimePicker'
import DeleteButton from 'components/delete-button/DeleteButton'
import S3Image from 'components/s3-image/S3Image'
import React from 'react'
import { NavLink } from 'react-router-dom'
import PostTo from './PostTo'
import ScheduleContent from './ScheduleContent'
import styles from './ScheduleItem.module.scss'

function ScheduleItem({ item, handleDelete, handleDateConfirm }) {
  const actions = () => {
    if (item.status === 'PENDING') {
      return (
        <div className={`${styles.actions} ${styles.three}`}>
          <DateTimePicker
            onClick={handleDateConfirm.bind(this)}
            onClickArgs={[item.id]}
          />

          <PostTo item={item} />

          <DeleteButton
            text='Are you sure want to delete this pending post?'
            deleteId={item.id}
            onDelete={handleDelete.bind(this)}
          />
          <NavLink to={`/schedule/update/${item.id}`} exact>
            <FontAwesomeIcon icon={faPencilAlt} />
          </NavLink>
        </div>
      )
    } else {
      return (
        <div className={`${styles.actions} ${styles.three}`}>
          <DateTimePicker
            date={new Date(item.publishAt)}
            onClick={handleDateConfirm.bind(this)}
            onClickArgs={[item.id]}
            shortDate={true}
          />

          <PostTo item={item} />

          <DeleteButton
            text='Are you sure want to delete this post from your calender?'
            deleteId={item.id}
            onDelete={handleDelete}
          />

          <NavLink to={`/schedule/update/${item.id}`} exact>
            <FontAwesomeIcon icon={faPencilAlt} />
          </NavLink>
        </div>
      )
    }
  }

  return (
    <div className={styles.itemContainer}>
      <div className={styles.item}>
        <ScheduleContent>
          <div className={styles.content}>
            <div className={styles.textBody}>{item.content}</div>
            <div>
              <div className={`image-thumb`}>
                <S3Image path={item.imageUrl} height='110' width='180' />
              </div>
            </div>
          </div>
        </ScheduleContent>
      </div>
      {actions()}
    </div>
  )
}

export default ScheduleItem
